import axios from 'axios';
import isFunction from '../../Helpers/isFunction';
import {apiConfigs} from "../configs/api";
import {refreshTokenRequest} from "./handleAxios";

export const ApiService = {
  apiCall: ({
              endpoint = '',
              method = 'GET',
              query = {},
              headers = {},
              qsParams = {},
            }) => {
    const HTTPMethod = method.toLowerCase()
    const slug = window.location.pathname.split('/')[1];
    const localStorageUserData = localStorage.getItem('persist:auth');
    const userData = JSON.parse(localStorageUserData);
    const { token } = userData ? userData : {};
    const activeToken = token ? JSON.parse(token) : null;
    const currentHeaders = activeToken ? {
      ...headers,
      Authorization: `Bearer ${activeToken}`
    } : {
      ...headers
    }
    const api = axios.create({
      baseURL: `https://${slug}.${apiConfigs.hostUrl}`,
      headers: currentHeaders,
      params: qsParams || {},
    })
    api.interceptors.request.use((config) => {
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    return new Promise((resolve, reject) => {
      api[HTTPMethod](endpoint, query)
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          if(error.response?.data?.message && error.response.data.message === 'Signature has expired') {
            refreshTokenRequest(error.config, resolve, reject)
          } else {
            reject(error)
          }
        })
    })
  },
}

const newRequest = () => (next) => (action) => {
  if (action.type?.includes('persist') || !action?.endpoint) return next(action)

  const {
    endpoint,
    headers,
    method,
    query,
    qsParams,
    callback,
    callbackParams,
    specialParametr,
  } = action

  const onError = (error) => {
    const errorMessage = error.response.data || false;
    const errorCode = error.response.status;

    // const errorObj = {
    //   ...action,
    //   type: action.type + '.FAILURE',
    //   statusCode: errorCode,
    //   payload: {...errorMessage, errorCode},
    // }
    //
    // next(errorObj);
    //
    // throw errorObj;

    return next({
      ...action,
      type: action.type + '.FAILURE',
      statusCode: errorCode,
      payload: {...errorMessage, errorCode},
    })
  }

  const onSuccess = (response) => {
    if (isFunction(callback)) {
      callback.apply(null, ...callbackParams)
    }
    return next({
      ...action,
      type: action.type + '.SUCCESS',
      statusCode: response.status,
      payload: specialParametr
        ? { ...response?.data, specialParametr }
        : response?.data,
    })
  }

  const apiRequest = ApiService.apiCall({
    endpoint,
    headers,
    method,
    query,
    qsParams,
  })

  const onStart = () => {
    return next({
      ...action,
      type: action.type,
    })
  }

  onStart();

  return apiRequest
    .then(onSuccess, onError)
    .catch((error) => console.error('Request error', error))
}

export default newRequest;
