import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { 
  // getCurrentUser, 
  GET_CURRENT_USER, 
  CLEAR_AUTH_ERRORS 
} from '../../../actions/auth';
import { getSlug } from '../../../selectors/tenant';


function* onSignIn(action) {
  yield put({ type: CLEAR_AUTH_ERRORS });
  // const token = action?.payload?.api_token;
  const payload = action?.payload;

  // const resultCall = yield call(getCurrentUser, token);

  // const resultPut = yield put(resultCall);

  // const result = yield call(() => resultPut);

  yield put({ type: GET_CURRENT_USER, payload: payload });

  const slug = yield select(getSlug);

  yield put(push(`/${slug}`));

  yield true;
}

export default onSignIn;
