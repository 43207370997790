import React, {useEffect, useMemo} from 'react';

import {useHistory, useLocation} from 'react-router-dom';

import './ContentWrapper.scss';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';


import NavBar from '../../Navbar';
import Sidebar from '../../Sidebar';

import {navbarList} from './helpers/navbarList';
import {useSelector} from 'react-redux';
import {getSlug} from '../../../Redux/selectors/tenant';
import {getCurrentUser, getIsAuth} from "../../../Redux/selectors/auth";
import {useMediaQuery, useTheme} from "@material-ui/core";
import MobileSidebar from "../../mobileSidebar"

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        background: '#F4F9FF',
    },
    sidebar: {
        width: '100%',
        position: 'relative',
        zIndex: 100,
        background: 'rgba(26, 129, 224, 1)',
        top: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        overflow: 'auto',
        backgroundColor: '#F4F9FF',
        width: '100%',
        boxSizing: 'border-box',
        '&.mobile': {
            // height: 'calc(100% - 100px)',
            // height: '100vh',
            // paddingBottom: 100
        }
    },
    headerContainer: {
        padding: '5px 28px 0 28px',
        height: '60px',
        position: 'sticky',
        top: 0,
        width: '100%',
        right: 0,
        backgroundColor: '#F4F9FF',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            // display: 'none',
            zIndex: 100,
            position: 'absolute',
            top: 50,
            background: 'none',
        },
    },
    childrenContainer: {
        height: 'calc(100% - 60px)',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        position: 'relative',
        ['@media (max-width:960px)']: {
            padding: '20px',
            // height: 'calc(100vh - 140px)',
            height: 'calc(100% - 60px)',
        },
    },
    childrenWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        position: 'relative',
        boxSizing: 'border-box',
        padding: '0px 0 24px',
        [theme.breakpoints.down('sm')]: {
            // padding: '0px 0 30px',
            padding: 0,
        },
        ['@media (max-width:610px)']: {
            // padding: '0px 0 30px',
            padding: 0,
        },
    },
    mobileFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0 35px 0'
    }
}));

const ContentWrapper = ({children}) => {
    const classes = useStyles();
    const theme = useTheme(),
        isSmall = useMediaQuery(theme.breakpoints.down("sm")),
        isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const location = useLocation();
    const activeLocation = location.pathname;
    const history = useHistory();
    const slug = useSelector(getSlug);
    const user = useSelector(getCurrentUser);
    const isAuth = useSelector(getIsAuth);

    useEffect(() => {
        if (isAuth && !!user?.missing_fields?.length) {
            history.push(`/${slug}/profile`)
        }
    }, [isAuth, user]);

    const isCalendar = useMemo(() => {
        return isSmall && location.pathname.includes('/appointment');
    }, [location, isSmall])

    const checkArray = (list) => {
        return list.includes(activeLocation) || list.some(item => activeLocation === `/${slug}` ? item === activeLocation : `${item}/`.includes(activeLocation)) || activeLocation.includes('user-appointments');
    }

    const isNavBar = checkArray(navbarList.map(r => r?.replace(':slug', slug)));

    return (
        <Grid container component="div" className={classes.root}>
            <Grid item xs={12} sm={12} md={3} className={classes.sidebar}>
                <Sidebar/>
            </Grid>
            <Grid
                item xs={12} sm={12} md={9}
                className={`${classes.content} ${isSmall ? 'mobile' : ''}`}
            >
                {isSmall && <MobileSidebar fullWidth={!isMobile}/>}
                <div className={classes.headerContainer}>
                    {isNavBar ? <NavBar/> : null}
                </div>
                <div className={classes.childrenContainer} style={isCalendar ? {
                    padding: '20px 0 20px 0',
                } : {}}>
                    <div className={classes.childrenWrapper}>
                        {children}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default React.memo(ContentWrapper);
