import React, {FC} from 'react';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import {getCurrentTenant} from '../../Redux/selectors/tenant';
import Avatar from "../Avatar";

const useStyles = makeStyles(() => ({
    tenantName: {
        fontWeight: 500,
        fontSize: '17px',
        color: 'white',
        marginBottom: 28,
        marginTop: 18,
        textAlign: "center",
    }
}));

const TenantHeader: FC = () => {
    const classes = useStyles();
    const tenant = useSelector(getCurrentTenant)
    return tenant ? (
        <>
            <Avatar/>
            <div className={classes.tenantName}>
                <span>{tenant?.name || ''}</span>
            </div>
        </>
    ) : <></>;
};

export default React.memo(TenantHeader);