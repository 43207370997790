import { toast, ToastOptions } from 'react-toastify';

import { Notification } from './notification/';

const config: ToastOptions = {
  autoClose: 5000,
  closeButton: false,
  type: 'info',
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  style: {
    minHeight: undefined
  },
};

class NotificationsProvider {
  success = (title: string | undefined, message: string) => {
    toast(
      <Notification type="success" title={title} message={message} />,
      config
    );
  };

  info = (title: string | undefined, message: string) => {
    toast(<Notification type="info" title={title} message={message} />, config);
  };

  error = (title: string | undefined, message: string) => {
    toast(
      <Notification type="error" title={title} message={message} />,
      config
    );
  };
}

export const NotificationService = Object.freeze(new NotificationsProvider());
