import React, {FC} from 'react';

import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider,} from '@material-ui/pickers';

import {Picker} from '../../ui';

import 'react-phone-input-2/lib/style.css';

import {makeStyles} from '@material-ui/core/styles';
import {styles} from '../../styles';
import moment from "moment";

const useStyles = makeStyles(() => ({
    birthdayContainer: {
        position: 'relative',
    },
    picker: {
        '&.MuiPickersToolbar-toolbar': {
            backgroundColor: `${styles.mainColor} !important`
        },
        '& .MuiOutlinedInput-input': {
            paddingLeft: 20,
            height: 26,
        }
    },
    info: {
        margin: '-10px 0 10px',
    }
}));

type IProps = {
    touched: any;
    style?: any;
    errors: any;
    values: any;
    field?: string;
    placeholder?: string;
    setFieldValue: (field: string, value: any) => void;
    customClass?: string;
    setFieldTouched: (field: string) => void;
    minDate?: any;
    maxDate?: any;
}

const BirthPicker: FC<IProps> = ({
                         touched,
                         style,
                         errors,
                         values,
                         field = 'dob',
                         placeholder = 'Date of birth (MMDDYYYY)',
                         setFieldValue,
                         customClass,
                         setFieldTouched = () => {},
                         minDate = `${new Date().getFullYear() - 100}-01-01`,
                         maxDate = `${new Date().getFullYear()}-01-01`,
                     }: IProps) => {
    const classes = useStyles();

    return (
        <div className={`${classes.birthdayContainer} ${customClass}`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Picker
                    className={classes.picker}
                    variant="inline"
                    style={style ? style : {}}
                    minDate={moment(minDate)}
                    maxDate={moment(maxDate)}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    inputProps={{ className: 'inspectlet-sensitive' }}
                    error={touched?.[field] && errors?.[field]}
                    helperText={touched?.[field] && errors?.[field]}
                    placeholder={placeholder}
                    value={values[field]}
                    onBlur={() => setFieldTouched(field)}
                    onChange={value => setFieldValue(field, value)}
                    onClose={() => setFieldTouched(field)}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default React.memo(BirthPicker);
