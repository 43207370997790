import {ITypeScreen} from "../../Helpers/ErrorOrSuccessPageTemplates/interfaces";

export interface IModalState {
    errorOrSuccessScreen: IErrorOrSuccessScreen,
    webCameraModal: IWebCameraModal,
    changingInsuranceModal: IChangingInsuranceModal,
    cancelAppointmentModal: ICancelAppointmentModal,
    isOpen: boolean,
    icon: string | null,
    title: string | null,
    description: string | null,
    submitText: string | null,
    cancelText: string | null,
    customChildren?: boolean | any;
    activeLeft?: boolean;
    contentClass?: string;
    onSuccess?: (() => void);
}

export interface ICancelAppointmentModal {
    typeModal: IModalTypes
    isOpen: boolean,
    title: string | null,
    subtitle: string | null,
    isDeleteCard: boolean,
    handleSubmit: null |  ((setIsSubmit: (value: boolean) => void) => void),
}

export interface IChangingInsuranceModal {
    isOpen: boolean,
    isSubmitted: boolean,
}

export interface IWebCameraModal {
    isOpen: boolean,
    icon: string | null,
    title: string | null,
    description: string | null,
    submitText: string | null,
    cancelText: string | null,
}

export type IErrorOrSuccessScreen = Partial<IErrorOrSuccessScreenMapper<IErrorOrSuccessScreenValues>>

type IErrorOrSuccessScreenMapper<Type> = {
    [Property in keyof Type]: Type[Property] | null
};

interface IErrorOrSuccessScreenValues {
    isOpen: boolean;
    isAnimation: boolean;
    nextAction: () => void;
    animation: string;
    title: string;
    subtitle: string;
    typeScreen: ITypeScreen;
    description: Array<string>;
    isChat: boolean;
    link: string;
    onLink: string;
    isResetPasswordWithEmail: boolean;
    onResendLink: () => void;
    unmountLink: string;
    onComplete: () => void;
    isExpirationTime: boolean;
    isMyAppointments: boolean;
}

export enum IModalTypes {
    REMOVE_INSURANCE = "REMOVE_INSURANCE",
    CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT",
    DELETE_CARD = "DELETE_CARD",
}