import React, {FC, useState} from 'react';

import phone from '../../assets/phone2.svg';
import mail from '../../assets/mail2.svg';
import chatIcon from '../../assets/chatIcon.svg'
import appointmentsIcon from '../../assets/appointmentsIcon.svg'
import flightHealth from '../../assets/preview.svg';

import './SideBar.scss';
import TenantHeader from '../TenantHeader';
import {useDispatch, useSelector} from "react-redux";
import {getCurrentTenant, getSlug} from "../../Redux/selectors/tenant";
import {useHistory} from "react-router-dom";
import {getIsAuth} from "../../Redux/selectors/auth";
import {changeOrder} from "../../Redux/actions/order";
import {closeErrorOrSuccessScreen} from "../../Redux/actions/modal";
import {ctaClickEvent} from "../../Redux/actions/tenant";

const Sidebar: FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const slug = useSelector(getSlug)
    const isAuth = useSelector(getIsAuth);
    const activeTenant = useSelector(getCurrentTenant);
    const [isShowPhone, setIsShowPhone] = useState(false);

    return (
        <div className={`sidebar-container sidebar-background`}>
            <TenantHeader/>
            {!navigator.userAgent.includes('Windows') ? <a href={`sms:${activeTenant?.phone || ''}`} onClick={() => dispatch(ctaClickEvent({
                destination: `sms:${activeTenant?.phone || ''}`,
                location: 'sidebar',
                page_title: history.location.pathname,
                name: "text us",
                type: "button",
                url: window.location.href,
            }))} className="info-card">
                <img src={chatIcon} alt='chat'/>
                <span>Text Us</span>
            </a> : null}
            {(!navigator.userAgent.includes('Windows') && !navigator.userAgent.includes('Mac OS')) ? <a href={`tel:${activeTenant?.phone || ''}`} onClick={() => dispatch(ctaClickEvent({
                destination: `tel:${activeTenant?.phone || ''}`,
                location: 'sidebar',
                page_title: history.location.pathname,
                name: "call us",
                type: "button",
                url: window.location.href,
            }))} className="info-card">
                <img src={phone} alt='call'/>
                <span>Call Us</span>
            </a> : <p className="info-card" onClick={() => setIsShowPhone(!isShowPhone)}>
                <img src={phone} alt='call'/>
                <span>{isShowPhone && activeTenant?.phone ? activeTenant?.phone : `Call Us`}</span>
            </p>}
            <a href={`mailto:${activeTenant.name === 'Lexington Medical Associates' ? 'office@lexmednyc.com' : activeTenant?.email ? activeTenant?.email : ''}`} onClick={() => dispatch(ctaClickEvent({
                destination: `mailto:${activeTenant?.email || ''}`,
                location: 'sidebar',
                page_title: history.location.pathname,
                name: "email us",
                type: "button",
                url: window.location.href,
            }))} className="info-card">
                <img src={mail} alt='email'/>
                <span>Email Us</span>
            </a>
            {isAuth && <button className="info-card" onClick={() => {
                dispatch(closeErrorOrSuccessScreen())
                dispatch(changeOrder({
                    appointment: {},
                    doctor: {},
                    reason: {},
                    editedOrder: {},
                    isEditBooking: false,
                    isEdited: false,
                }))
                history.push(`/${slug}/user-appointments`)
            }}>
                <img src={appointmentsIcon}/>
                <span>My appointments</span>
            </button>}
            <img className='flight-health-logo' src={flightHealth} alt="flight health logo"/>
        </div>
    );

};

export default React.memo(Sidebar)
