import axios from 'axios';
import {apiConfigs} from "../configs/api";
import {store} from "../index";

import {refreshTokenAction} from "../actions/auth";

export function refreshTokenRequest(failedRequestConfig, resolve, reject){
    const slug = window.location.pathname.split('/')[1];
    const localStorageUserData = localStorage.getItem('persist:auth');
    const userData = JSON.parse(localStorageUserData);
    const { token } = userData;
    const { refreshToken } = userData;
    const activeRefreshToken = JSON.parse(refreshToken)
    const activeToken = JSON.parse(token)
    axios.post(`https://${slug}.${apiConfigs.hostUrlV2}refresh_token?token=${activeToken}&refresh_token=${activeRefreshToken}`,)
        .then((res) => {
            const {token} = res.data;
            const {refresh_token} = res.data
            localStorage.setItem('persist:auth', JSON.stringify({
                ...userData,
                token: JSON.stringify(token),
                refreshToken: JSON.stringify(refresh_token),
            }))
            store.dispatch(refreshTokenAction({token, refresh_token}))
            failedRequestConfig.headers['Authorization'] = `Bearer ${token}`
            return axios(failedRequestConfig).then((data) => resolve(data)).catch((error) => reject(error))
        }).catch((error) => console.log(error))
}

export const ApiService = {
    apiCall: ({
                  endpoint = '',
                  method = 'GET',
                  query = {},
                  headers = {},
                  qsParams = {},
              }) => {
        const HTTPMethod = method.toLowerCase()
        const slug = window.location.pathname.split('/')[1];
        const localStorageUserData = localStorage.getItem('persist:auth');
        const userData = JSON.parse(localStorageUserData);
        const { token } = userData;
        const activeToken = JSON.parse(token)
        const currentHeaders = activeToken ? {
            ...headers,
            Authorization: `Bearer ${activeToken}`
        } : {
            ...headers
        }
        const api = axios.create({
            baseURL: `https://${slug}.${apiConfigs.hostUrlV2}`,
            headers: currentHeaders,
            params: qsParams || {},
        })
        api.interceptors.request.use((config) => {
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        return new Promise((resolve, reject) => {
            api[HTTPMethod](endpoint, query)
                .then((data) => {
                    resolve(data)
                })
                .catch((error) => {
                    if(error.response?.data?.message && error.response.data.message === 'Signature has expired') {
                        refreshTokenRequest(error.config, resolve, reject)
                    }
                    reject(error)
                })
        })
    },
}

export default ApiService;