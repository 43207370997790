import {IResponseCustomAction} from "./index";

export const OPEN_MODAL = 'modal/OPEN_MODAL'
export const openModal = (payload): IResponseCustomAction<string> => {
  return {
    type: OPEN_MODAL,
    payload
  }
}

export const CLOSE_MODAL = 'modal/CLOSE_MODAL'
export const closeModal = (): IResponseCustomAction<string> => {
  return {
    type: CLOSE_MODAL,
  }
}

export const OPEN_WEB_CAMERA_MODAL = 'modal/OPEN_WEB_CAMERA_MODAL'
export const openWebCameraModal = (payload): IResponseCustomAction<string> => {
  return {
    type: OPEN_WEB_CAMERA_MODAL,
    payload
  }
}

export const CLOSE_WEB_CAMERA_MODAL = 'modal/CLOSE_WEB_CAMERA_MODAL'
export const closeWebCameraModal = (): IResponseCustomAction<string> => {
  return {
    type: CLOSE_WEB_CAMERA_MODAL,
  }
}

export const OPEN_ERROR_OR_SUCCESS_SCREEN = 'modal/OPEN_ERROR_OR_SUCCESS_SCREEN'
export const openErrorOrSuccessScreen = (payload): IResponseCustomAction<string> => {
  return {
    type: OPEN_ERROR_OR_SUCCESS_SCREEN,
    payload
  }
}

export const CLOSE_ERROR_OR_SUCCESS_SCREEN = 'modal/CLOSE_ERROR_OR_SUCCESS_SCREEN'
export const closeErrorOrSuccessScreen = (): IResponseCustomAction<string> => {
  return {
    type: CLOSE_ERROR_OR_SUCCESS_SCREEN,
  }
}

export const OPEN_CHANGING_INSURANCE_MODAL = 'modal/OPEN_CHANGING_INSURANCE_MODAL'
export const openChangingInsuranceModal = (payload): IResponseCustomAction<string> => {
  return {
    type: OPEN_CHANGING_INSURANCE_MODAL,
    payload
  }
}

export const CLOSE_CHANGING_INSURANCE_MODAL = 'modal/CLOSE_CHANGING_INSURANCE_MODAL'
export const closeChangingInsuranceModal = (): IResponseCustomAction<string> => {
  return {
    type: CLOSE_CHANGING_INSURANCE_MODAL,
  }
}

export const OPEN_CANCEL_APPOINTMENT_MODAL = 'modal/OPEN_CANCEL_APPOINTMENT_MODAL'
export const openCancelAppointmentModal = (payload): IResponseCustomAction<string> => {
  return {
    type: OPEN_CANCEL_APPOINTMENT_MODAL,
    payload
  }
}

export const CLOSE_CANCEL_APPOINTMENT_MODAL = 'modal/CLOSE_CANCEL_APPOINTMENT_MODAL'
export const closeCancelAppointmentModal = (): IResponseCustomAction<string> => {
  return {
    type: CLOSE_CANCEL_APPOINTMENT_MODAL,
  }
}