import {createReducer} from '../../Helpers/redux';

import appointmentsConstants from '../constants/appointments'

import {IAppointmentsState} from "../types/appointments";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import moment from "moment/moment";

const initialState: IAppointmentsState = {
    appointments: [],
    prescriptions: [],
    error: null,
    busyApps: [],
    availableApps: [],
    loading: false,
    successBooking: false,
    prevId: null,
    appointmentTypes: null,
    currentDate: new Date(),
    nextAppointment: null,
    monthAppointments: [],
}

const persistConfig = {
    key: 'appointment',
    storage,
    whitelist: ['currentDate'],
}

const handlers = {
    [appointmentsConstants.GET_APPOINTMENTS_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            appointments: payload,
        }
    },
    [appointmentsConstants.GET_MONTH_APPOINTMENTS_SUCCESS]: (prevState, {payload}) => {
        const open = payload.appointments.map(el => {
            if(el.front_status === "open"){
                return parseInt(moment(el.datetime_start).format('DD'))
            }
        });
        return {
            ...prevState,
            monthAppointments: [...new Set(open.filter(Boolean))],
        }
    },
    [appointmentsConstants.GET_PRESCRIPTIONS_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            prescriptions: payload
        }
    },
    [appointmentsConstants.GET_AVAILABLE_APPOINTMENTS_SUCCESS]: (prevState, {payload}) => {
        const statusArray = payload.appointments.map(el => ({
            ...el,
            status: 'available'
        }));
        return {
            ...prevState,
            availableApps: statusArray,
            nextAppointment: payload?.next_appointment,
            error: null
        }
    },
    [appointmentsConstants.BOOK_APPOINTMENT]: (prevState, payload) => {
        return {
            ...prevState,
            loading: true,
            prevId: payload.endpoint.split('/')[1],
        }
    },
    [appointmentsConstants.BOOK_APPOINTMENT_SUCCESS]: (prevState) => {
        return {
            ...prevState,
            error: null,
            loading: false,
            successBooking: true,
        }
    },
    [appointmentsConstants.RESET_BOOKING]: (prevState) => {
        return {
            ...prevState,
            successBooking: false
        }
    },
    [appointmentsConstants.BOOK_APPOINTMENT_FAILURE]: (prevState, {payload}) => {
        return {
            ...prevState,
            error: payload,
            loading: false
        }
    },
    [appointmentsConstants.GET_APPOINTMENT_TYPES_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            appointmentTypes: payload.appointment_types,
        }
    },
    [appointmentsConstants.SET_CURRENT_DATE]: (prevState, {payload}) => {
        return {
            ...prevState,
            currentDate: payload,
        }
    },
    [appointmentsConstants.CLEAR_APPOINTMENTS_STATE]: () => {
        return {
            ...initialState,
        }
    }
}

export default persistReducer(persistConfig, createReducer(initialState, handlers))
