import { createSelector } from 'reselect'

const getState = (state) => state.auth

export const getIsChangingInsurance = createSelector(getState, (auth) => auth.isChangingInsurance);

export const getIsAuth = createSelector(getState, (auth) => auth.token);

export const getError = createSelector(getState, (auth) => auth.error);

export const getVerifyError = createSelector(getState, (auth) => auth.verifyError);

export const getStatus = createSelector(getState, (auth) => auth.status);

export const getAuthFormValue = createSelector(getState, (auth) => auth.authFormValue);

export const getSignInError = createSelector(getState, (auth) => auth.signInError);

export const getPhoneSendError = createSelector(getState, (auth) => auth.phoneSendError);

export const getCurrentUser = createSelector(getState, (auth) => auth.user);

export const getUserNumber = createSelector(getState, (auth) => auth.user?.phone_number);

export const getUserError = createSelector(getState, (auth) => auth.error);

export const getUploadedPhoto = createSelector(getState, (auth) => auth.uploadedPhoto);

export const getTenantProfile = createSelector(getState, (auth) => auth.tenantProfile);

export const getDataForRecovery = createSelector(getState, (auth) => auth.dataForRecovery);

export const getPrefillData = createSelector(getState, (auth) => auth.prefillData);

export const getIsNewPatientFlow = createSelector(getState, (auth) => auth.isNewPatientFlow);

export const getIsSignInOnNewPatientFlow = createSelector(getState, (auth) => auth.isSignInOnNewPatientFlow);

export const getIsAuthInStaging = createSelector(getState, (auth) => auth.isAuthInStaging);

export const getTypeAuthorization = createSelector(getState, (auth) => auth.typeAuthorization);

export const getIsRegistrationFlow = createSelector(getState, (auth) => auth.isRegistrationFlow);