export enum ITenantTypeAction {
    GET_TENANTS = 'tenant/GET_TENANTS',
    GET_TENANTS_SUCCESS = 'tenant/GET_TENANTS.SUCCESS',
    GET_TENANTS_FAILURE = 'tenant/GET_TENANTS.FAILURE',

    GET_TENANT = 'tenant/GET_TENANT',
    GET_TENANT_SUCCESS = 'tenant/GET_TENANT.SUCCESS',
    GET_TENANT_FAILURE = 'tenant/GET_TENANT.FAILURE',

    CLEAR_TENANT = 'tenant/GET_TENANT',
    CLEAR_TENANT_SUCCESS = 'tenant/GET_TENANT.SUCCESS',
    CLEAR_TENANT_FAILURE = 'tenant/GET_TENANT.FAILURE',

    SET_SLUG = 'tenant/SET_SLUG',
    SET_SLUG_SUCCESS = 'tenant/SET_SLUG.SUCCESS',
    SET_SLUG_FAILURE = 'tenant/SET_SLUG.FAILURE',

    SET_INSURANCE_ID = 'tenant/SET_INSURANCE_ID',
    SET_INSURANCE_ID_SUCCESS = 'tenant/SET_INSURANCE_ID.SUCCESS',
    SET_INSURANCE_ID_FAILURE = 'tenant/SET_INSURANCE_ID.FAILURE',

    CREATE_INSURANCE = 'tenant/CREATE_INSURANCE',
    CREATE_INSURANCE_SUCCESS = 'tenant/CREATE_INSURANCE.SUCCESS',
    CREATE_INSURANCE_FAILURE = 'tenant/CREATE_INSURANCE.FAILURE',

    CHOOSE_NEW_INSURANCE = 'tenant/CHOOSE_NEW_INSURANCE',
    CHOOSE_NEW_INSURANCE_SUCCESS = 'tenant/CHOOSE_NEW_INSURANCE.SUCCESS',
    CHOOSE_NEW_INSURANCE_FAILURE = 'tenant/CHOOSE_NEW_INSURANCE.FAILURE',

    CUSTOMER_EVENT = 'tenant/CUSTOMER_EVENT',

    CTA_CLICK_EVENT = 'tenant/CTA_CLICK_EVENT',

    SEND_ERROR_REPORT = 'tenant/SEND_ERROR_REPORT',

    GET_TENANT_QUESTIONNAIRES = 'tenant/GET_TENANT_QUESTIONNAIRES',
    GET_TENANT_QUESTIONNAIRES_SUCCESS = 'tenant/GET_TENANT_QUESTIONNAIRES.SUCCESS',
    GET_TENANT_QUESTIONNAIRES_FAILURE = 'tenant/GET_TENANT_QUESTIONNAIRES.FAILURE',

}