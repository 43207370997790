import React, {useCallback, useRef, useState} from 'react';
import './style.scss';
import {useDispatch, useSelector} from "react-redux";
import {getCurrentTenant} from "../../Redux/selectors/tenant";
import logo from '../../assets/logo.svg'
import {useClickOutside} from "../../hooks";
import {ctaClickEvent} from "../../Redux/actions/tenant";
import {useHistory} from "react-router-dom";

const MobileSidebar = ({fullWidth}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activeTenant = useSelector(getCurrentTenant);
    const currentTenant = useSelector(getCurrentTenant);
    const refPopup = useRef(null);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const onOpenPopup = useCallback(() => setIsOpenPopup(true), []);
    const onClosePopup = useCallback(() => setIsOpenPopup(false), []);

    const onTextUs = useCallback(() => {
        dispatch(ctaClickEvent({
            destination: `sms:${activeTenant?.phone || ''}`,
            location: 'sidebar',
            page_title: history.location.pathname,
            name: "text us",
            type: "button",
            url: window.location.href,
        }))
        onClosePopup()
    }, [activeTenant])

    const onCallUs = useCallback(() => {
        dispatch(ctaClickEvent({
            destination: `tel:${activeTenant?.phone || ''}`,
            location: 'sidebar',
            page_title: history.location.pathname,
            name: "call us",
            type: "button",
            url: window.location.href,
        }))
        onClosePopup()
    }, [activeTenant])

    const onEmailUs = useCallback(() => {
        dispatch(ctaClickEvent({
            destination: `mailto:${activeTenant?.email || ''}`,
            location: 'sidebar',
            page_title: history.location.pathname,
            name: "email us",
            type: "button",
            url: window.location.href,
        }))
        onClosePopup()
    }, [activeTenant])

    useClickOutside(refPopup, onClosePopup)


    return (
        <div className={`mobileSidebar ${fullWidth ? 'fullWidth' : ''}`}>
            <img className={`mobileSidebar-image ${!currentTenant?.logo?.url && 'mobileSidebar-flightLogo'}`} src={currentTenant?.logo?.url || logo}/>
            <div className="mobileSidebar-info">
                <p className="mobileSidebar-title">{currentTenant.name}</p>
                <div className="mobileSidebar-supportWrapper" ref={refPopup} >
                    <div className="mobileSidebar-blocks" onClick={isOpenPopup ? onClosePopup : onOpenPopup}>
                        <a className="mobileSidebar-block">
                            <span className="mobileSidebar-block-title">Contact us</span>
                        </a>
                    </div>
                    <div className="mobileSidebar-popup" style={{display: isOpenPopup ? 'flex' : 'none'}}>
                        <a className="mobileSidebar-popupItem" href={`sms:${activeTenant?.phone || ''}`} onClick={onTextUs}>
                            <span className="mobileSidebar-block-title">Text us</span>
                        </a>
                        <a className="mobileSidebar-popupItem" href={`tel:${activeTenant?.phone || ''}`} onClick={onCallUs}>
                            <span className="mobileSidebar-block-title">Call us</span>
                        </a>
                        <a className="mobileSidebar-popupItem" href={`mailto:${activeTenant?.email || ''}`} onClick={onEmailUs}>
                            <span className="mobileSidebar-block-title">Email us</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(MobileSidebar);