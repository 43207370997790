import React, {FC, useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import './style.scss'

import {getErrorOrSuccessScreen} from "../../Redux/selectors/modal";
import {closeErrorOrSuccessScreen} from "../../Redux/actions/modal";

import AnimationComponent from "./AnimationComponent";
import SuccessOrErrorComponent from "./SuccessOrErrorComponent";
import {getSlug} from "../../Redux/selectors/tenant";
import {changeOrder} from "../../Redux/actions/order";

const ErrorOrSuccessPage: FC = () => {
    const history = useHistory()
    const dispatch = useDispatch();
    const slug = useSelector(getSlug);
    const errorOrSuccessScreen = useSelector(getErrorOrSuccessScreen);

    const onLink = useCallback(() => {
        errorOrSuccessScreen?.onLink && history.push(errorOrSuccessScreen.onLink);
        dispatch(closeErrorOrSuccessScreen());
    }, [errorOrSuccessScreen])

    const onExpiredCancel = useCallback(() => {
        dispatch(changeOrder({appointment: {}, appointmentExpirationTime: null}))
        history.push(`/${slug}/appointment`)
        dispatch(closeErrorOrSuccessScreen());
    }, [slug])

    const onExpiredTryAgain = useCallback(() => {
        dispatch(changeOrder({appointment: {}, appointmentExpirationTime: null}))
        history.push(`/${slug}/appointment`)
        dispatch(closeErrorOrSuccessScreen());
    }, [slug])

    const onBookNewAppointment = useCallback(() => {
        history.push(`/${slug}/`)
        dispatch(closeErrorOrSuccessScreen());
    }, [slug])

    const onViewMyAppointment = useCallback(() => {
        history.push(`/${slug}/user-appointments`)
        dispatch(closeErrorOrSuccessScreen());
    }, [slug])

    const onSendReport = useCallback(() => {
        history.push(`/${slug}/support`)
        dispatch(closeErrorOrSuccessScreen());
    }, [slug])

    const content = useMemo(() => {
        if(errorOrSuccessScreen.isAnimation) {
            return <AnimationComponent
                onComplete={errorOrSuccessScreen.nextAction}
                animation={errorOrSuccessScreen?.animation ? errorOrSuccessScreen?.animation : 'default'}
                title={errorOrSuccessScreen?.title}
                subtitle={errorOrSuccessScreen?.subtitle}
            />;
        }
        return <SuccessOrErrorComponent
            type={errorOrSuccessScreen?.typeScreen}
            title={errorOrSuccessScreen?.title}
            subtitle={errorOrSuccessScreen?.subtitle}
            description={errorOrSuccessScreen?.description}
            isChat={errorOrSuccessScreen?.isChat}
            isMyAppointments={errorOrSuccessScreen?.isMyAppointments}
            onBookNewAppointment={onBookNewAppointment}
            onViewMyAppointment={onViewMyAppointment}
            isExpirationTime={errorOrSuccessScreen.isExpirationTime}
            onExpiredCancel={onExpiredCancel}
            onExpiredTryAgain={onExpiredTryAgain}
            onComplete={errorOrSuccessScreen?.nextAction}
            link={errorOrSuccessScreen?.link}
            onLink={onLink}
            isResetPasswordWithEmail={errorOrSuccessScreen?.isResetPasswordWithEmail}
            onResendLink={errorOrSuccessScreen?.onResendLink}
            onSendReport={onSendReport}
        />
    }, [errorOrSuccessScreen])

    return (
        <div className='error-or-success-page'>
            {content}
        </div>
    )
}

export default React.memo(ErrorOrSuccessPage)