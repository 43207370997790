import {createReducer} from '../../Helpers/redux';
import storage from 'redux-persist/lib/storage';

import {
    CLOSE_CANCEL_APPOINTMENT_MODAL,
    CLOSE_CHANGING_INSURANCE_MODAL,
    CLOSE_ERROR_OR_SUCCESS_SCREEN,
    CLOSE_MODAL,
    CLOSE_WEB_CAMERA_MODAL,
    OPEN_CANCEL_APPOINTMENT_MODAL,
    OPEN_CHANGING_INSURANCE_MODAL,
    OPEN_ERROR_OR_SUCCESS_SCREEN,
    OPEN_MODAL,
    OPEN_WEB_CAMERA_MODAL
} from '../actions/modal';
import {persistReducer} from "redux-persist";
import {IModalState, IModalTypes} from "../types/modal";

const initialState: IModalState = {
    errorOrSuccessScreen: {
        isOpen: null,
        isAnimation: null,
        nextAction: null,
        animation: null,
        title: null,
        subtitle: null,
        typeScreen: null,
        description: null,
        isChat: null,
        link: null,
        onLink: null,
        isResetPasswordWithEmail: null,
        onResendLink: null,
        unmountLink: null,
        onComplete: null,
        isExpirationTime: null,
    },
    webCameraModal: {
        isOpen: false,
        icon: null,
        title: null,
        description: null,
        submitText: null,
        cancelText: null,
    },
    changingInsuranceModal: {
        isOpen: false,
        isSubmitted: false,
    },
    cancelAppointmentModal: {
        typeModal: IModalTypes.CANCEL_APPOINTMENT,
        isOpen: false,
        title: null,
        subtitle: null,
        isDeleteCard: false,
        handleSubmit: null,
    },
    isOpen: false,
    icon: null,
    title: null,
    description: null,
    submitText: null,
    cancelText: null,
}


const persistConfig = {
    key: 'modal',
    storage,
    blacklist: ['errorOrSuccessScreen', 'cancelAppointmentModal', 'changingInsuranceModal', 'webCameraModal', 'isOpen', 'icon', 'title', 'description', 'submitText', 'cancelText'],
}

const handlers = {
    [OPEN_MODAL]: (prevState, { payload }) => {
        return {
            ...prevState,
            ...payload,
        }
    },
    [CLOSE_MODAL]: (prevState) => {
        return {
            ...prevState,
            isOpen: false,
            icon: null,
            title: null,
            description: null,
            submitText: null,
            cancelText: null,
        }
    },
    [OPEN_WEB_CAMERA_MODAL]: (prevState, { payload }) => {
        return {
            ...prevState,
            webCameraModal: {...payload},
        }
    },
    [CLOSE_WEB_CAMERA_MODAL]: (prevState) => {
        return {
            ...prevState,
            webCameraModal: {
                isOpen: false,
                icon: null,
                title: null,
                description: null,
                submitText: null,
                cancelText: null,
            },
        }
    },
    [OPEN_ERROR_OR_SUCCESS_SCREEN]: (prevState, { payload }) => {
        return {
            ...prevState,
            errorOrSuccessScreen: {...payload},
        }
    },
    [CLOSE_ERROR_OR_SUCCESS_SCREEN]: (prevState) => {
        return {
            ...prevState,
            errorOrSuccessScreen: {
                isOpen: null,
                isAnimation: null,
                nextAction: null,
                animation: null,
                title: null,
                subtitle: null,
                typeScreen: null,
                description: null,
                isChat: null,
                link: null,
                onLink: null,
                isResetPasswordWithEmail: null,
                onResendLink: null,
                unmountLink: null,
            },
        }
    },
    [OPEN_CHANGING_INSURANCE_MODAL]: (prevState, {payload}) => {
        return {
            ...prevState,
            changingInsuranceModal: {...payload},
        }
    },
    [CLOSE_CHANGING_INSURANCE_MODAL]: (prevState) => {
        return {
            ...prevState,
            changingInsuranceModal: {
                isOpen: false,
                isSubmitted: false,
            },
        }
    },
    [OPEN_CANCEL_APPOINTMENT_MODAL]: (prevState, {payload}) => {
        return {
            ...prevState,
            cancelAppointmentModal: {...payload}
        }
    },
    [CLOSE_CANCEL_APPOINTMENT_MODAL]: (prevState) => {
        return {
            ...prevState,
            cancelAppointmentModal: {
                isOpen: false,
                title: null,
                subtitle: null,
                isDeleteCard: false,
                handleSubmit: null,
            }
        }
    }
}

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
)
