import { makeStyles } from '@material-ui/core/styles';

export const useNotificationStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%',
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '19px',
    lineHeight: '27px',
    letterSpacing: 0,
    fontWeight: 600,
    margin: '0 0 5px',
    color: '#858997',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    minWidth: '23px',
    height: '23px',
    marginRight: '15px',
    '& path': {
      stroke: '#E3001B',
    },
  },
  message: {
    fontFamily: "Montserrat",
    textAlign: 'left',
    fontSize: '15px',
    lineHeight: '29px',
    fontWeight: 400,
    letterSpacing: 0,
    color: '#858997',
    padding: 0,
    margin: 0,
  },
  strokeBlack: {
    '& path': {
      stroke: '#000',
    },
  },
});
