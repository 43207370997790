export enum IMethodRequest {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export interface IResponseCustomAction<Types, TypePayload = void> {
    method?: IMethodRequest;
    endpoint?: string;
    query?: {[key: string]: unknown}
    headers?: {[key: string]: unknown}
    qsParams?: {[key: string]: unknown}
    type: Types;
    payload?: TypePayload;
}

export interface IErrorResponse {
    response?: {
        data?: {
            message?: string;
        }
    }
}