import { createReducer } from '../../Helpers/redux';

import {
    GET_LOCATIONS
} from '../actions/locations';

import {ILocationsState} from "../types/locations";

const initialState: ILocationsState = {
    locations: [],
    error: null,
}

const handlers = {
    [GET_LOCATIONS + '.SUCCESS']: (prevState, { payload }) => {
        return {
            ...prevState,
            ...payload,
            error: null
        }
    },
    [GET_LOCATIONS + '.FAILURE']: (prevState, { payload }) => {
        return {
            ...prevState,
            error: payload
        }
    },
}

export default createReducer(initialState, handlers);