import { put } from 'redux-saga/effects'

import { CLEAR_AUTH_ERRORS } from '../../../actions/auth'
import { GET_CURRENT_USER } from '../../../actions/auth';

function* onSignUp(action) {
  yield put({ type: CLEAR_AUTH_ERRORS })

  const payload = action?.payload;

  yield put({ type: GET_CURRENT_USER, payload: payload });

  yield true
}

export default onSignUp
