import {withStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {styles} from "../../styles";

const StyledInput = withStyles(() => ({
    root: {
        '&.MuiFormControl-marginNormal': {
            marginTop: 8,
            marginBottom: 8,
        },

        '& .MuiFilledInput-root': {
            position: 'relative',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E1E6EC',
            boxSizing: 'border-box',
            boxShadow: '0px 6px 16px rgba(0, 115, 221, 0.1)',
            borderRadius: 8,

            '& .MuiFilledInput-input': {
                padding: '27px 12px 10px',
            },
            '&.Mui-focused': {
                border: `1px solid ${styles.mainColor}`,
            },
        },
        '& .MuiFilledInput-underline:after': {
            content: 'none',
        },

        '& .MuiFilledInput-underline:before': {
            content: 'none',
        }
    },
}))(TextField)

export default StyledInput;
