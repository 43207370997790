import { createSelector } from 'reselect';
import {IState} from "../types";

const getState = (state: IState) => state.modal;

export const getModal = createSelector(getState, (modal) => modal);

export const getWebCameraModal  = createSelector(getState, (modal) => modal.webCameraModal)

export const getErrorOrSuccessScreen = createSelector(getState, (modal) => modal.errorOrSuccessScreen)

export const getChangingInsuranceModal = createSelector(getState, (modal) => modal.changingInsuranceModal)

export const getCancelAppointmentModal = createSelector(getState, (modal) => modal.cancelAppointmentModal)