import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {createReducer} from '../../Helpers/redux';

import {
    CLEAR_AUTH_ERRORS,
    FIND_RECORD,
    LOG_OUT,
    LOGIN_APPLE,
    LOGIN_GOOGLE,
    RECOVERY_PASSWORD,
    REFRESH_TOKEN,
    RESET_STATUS,
    SEND_RECOVERY_LINK,
    SET_AUTH_FORM_VALUE,
    SET_DATA_FOR_RECOVERY, SET_IS_AUTH_IN_STAGING,
    SET_IS_CHANGING_INSURANCE,
    SET_IS_NEW_PATIENT_FLOW, SET_IS_REGISTRATION_FLOW,
    SET_IS_SIGN_IN_ON_PATIENT_FLOW,
    SET_PREFILL_DATA,
    SET_TYPE_AUTHORIZATION,
    SIGN_IN,
    SIGN_UP,
    SWITCH_AUTH_FORM,
    UPLOAD_AVATAR
} from '../actions/auth';
import {REQUEST_EMAIL, RESEND_CODE, VERIFY_BY_PHONE} from '../actions/verification';
import {CHANGE_USER_PARAMS, GET_PATIENT_INSURANCE, GET_USER} from "../actions/users";
import {GET_USER_UNIT} from '../actions/auth'
import {NotificationService} from "../../services";
import {ITenantTypeAction} from "../constants/tenant";


const initialState = {
    error: null,
    token: null,
    refreshToken: null,
    durationTokenStart: 0,
    durationTokenEnd: 0,
    user: {},
    tenantProfile: null,
    isLoading: false,
    status: false,
    verifyError: null,
    phoneSendError: null,
    authFormValue: null,
    signInError: null,
    isChangingInsurance: false,
    uploadedPhoto: null,
    dataForRecovery: null,
    prefillData: null,
    isNewPatientFlow: false,
    isSignInOnNewPatientFlow: false,
    isAuthInStaging: false,
    typeAuthorization: '',
    isRegistrationFlow: false,
}

const persistConfig = {
    key: 'auth',
    storage,
    blacklist: ['error', 'uploadedPhoto', 'signInError', 'isFormSwitched', 'switchAuthForm', 'status', 'phoneSendError', 'verifyError'],
}

const handlers = {
    [SIGN_IN]: (prevState) => {
        return {
            ...prevState,
            signInError: null,
            isLoading: true,
            status: false
        }
    },
    [SET_IS_CHANGING_INSURANCE]: (prevState, {checked}) => {
        return {
            ...prevState,
            isChangingInsurance: checked,
        }
    },
    [SIGN_IN + '.SUCCESS']: (prevState, {payload}) => {
        NotificationService.success(undefined, 'Welcome to Flight');
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
            isLoading: false,
            status: true,
            signInError: null,
        }
    },
    [GET_USER_UNIT + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            user: payload,
        }
    },
    [SIGN_IN + '.FAILURE']: (prevState, {payload}) => {
        NotificationService.error(undefined, 'Failure authorization');
        return {
            ...prevState,
            signInError: payload.message,
            status: false
        }
    },
    [VERIFY_BY_PHONE]: (prevState) => {
        return {
            ...prevState,
        }
    },
    [VERIFY_BY_PHONE + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
            isLoading: false,
        }
    },
    [VERIFY_BY_PHONE + '.FAILURE']: (prevState, {payload}) => {
        return {
            ...prevState,
            status: false,
            verifyError: payload
        }
    },
    [ITenantTypeAction.CREATE_INSURANCE_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            user: payload,
        }
    },
    [ITenantTypeAction.CHOOSE_NEW_INSURANCE_SUCCESS]: (prevState) => {
        return {
            ...prevState,
        }
    },
    [RESEND_CODE + '.SUCCESS']: (prevState) => {
        return {
            ...prevState,
            status: true,
            phoneSendError: null,
        }
    },
    [RESEND_CODE]: (prevState) => {
        return {
            ...prevState,
            status: false,
            phoneSendError: null,
        }
    },
    [RESEND_CODE + '.FAILURE']: (prevState, {payload}) => {
        return {
            ...prevState,
            status: false,
            phoneSendError: {...payload},
        }
    },
    [REQUEST_EMAIL]: (prevState) => {
        return {
            ...prevState,
            status: false,
            phoneSendError: null,
        }
    },
    [REQUEST_EMAIL + '.FAILURE']: (prevState) => {
        return {
            ...prevState,
            status: true,
            phoneSendError: null,
        }
    },
    [REQUEST_EMAIL + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            status: false,
            phoneSendError: payload?.message === 'Success' ? {message: 'User with passed params does not exist'} : {...payload} ,
        }
    },
    [SWITCH_AUTH_FORM]: (prevState, {payload}) => {
        return {
            ...prevState,
            ...payload,
            status: false
        }
    },
    [SET_AUTH_FORM_VALUE]: (prevState, {payload}) => {
        return {
            ...prevState,
            ...payload
        }
    },
    [LOGIN_GOOGLE]: (prevState) => {
        return {
            ...prevState,
            error: null,
            isLoading: true
        }
    },
    [LOGIN_GOOGLE + '.SUCCESS']: (prevState, {payload}) => {
        NotificationService.success(undefined, 'Welcome to Flight');
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
            isLoading: false,
        }
    },
    [LOGIN_GOOGLE + '.FAILURE']: (prevState, {payload}) => {
        NotificationService.error(undefined, 'Failure authorization');
        return {
            ...prevState,
            error: payload,
        }
    },
    [LOGIN_APPLE]: (prevState) => {
        return {
            ...prevState,
            error: null,
            isLoading: true
        }
    },
    [LOGIN_APPLE + '.SUCCESS']: (prevState, {payload}) => {
        NotificationService.success(undefined, 'Welcome to Flight');
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
            isLoading: false,
        }
    },
    [LOGIN_APPLE + '.FAILURE']: (prevState, {payload}) => {
        NotificationService.error(undefined, 'Failure authorization');
        return {
            ...prevState,
            error: payload,
        }
    },
    [UPLOAD_AVATAR + '.SUCCESS']: (prevState) => {
        return {
            ...prevState,
            uploadedPhoto: null,
        }
    },
    [UPLOAD_AVATAR + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            user: {...prevState.user, ...payload},
            uploadedPhoto: payload.photo.url,
        }
    },
    [UPLOAD_AVATAR + '.FAILURE']: (prevState, {payload}) => {
        return {
            ...prevState,
            error: payload,
        }
    },
    [SIGN_UP]: (prevState) => {
        return {
            ...prevState,
            status: false
        }
    },
    [SIGN_UP + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
            status: true
        }
    },
    [SIGN_UP + '.FAILURE']: (prevState, {payload}) => {
        return {
            ...prevState,
            error: payload?.message ? payload.message : 'We\'re sorry, but something went wrong.',
            status: false
        }
    },
    [LOG_OUT]: (prevState) => {
        return {
            ...initialState,
            isAuthInStaging: prevState.isAuthInStaging,
        }
    },
    [CLEAR_AUTH_ERRORS]: (prevState) => {
        return {
            ...prevState,
            error: null,
            phoneSendError: null
        }
    },
    [GET_USER + '.SUCCESS']: (prevState, { payload }) => {
        return {
            ...prevState,
            user: payload,
        }
    },
    [CHANGE_USER_PARAMS + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            user: payload,
        }
    },
    [RESET_STATUS]: (prevState) => {
        return {
            ...prevState,
            status: false,
        }
    },
    [REFRESH_TOKEN]: (prevState, {payload}) => {
        return {
            ...prevState,
            token: payload.token,
            refreshToken: payload.refresh_token,
        }
    },
    [FIND_RECORD + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            tenantProfile: payload.tenant_profile,
        }
    },
    [SEND_RECOVERY_LINK + '.SUCCESS']: (prevState) => {
        return {
            ...prevState,
        }
    },
    [SET_DATA_FOR_RECOVERY]: (prevState, {payload}) => {
        return {
            ...prevState,
            dataForRecovery: payload,
        }
    },
    [RECOVERY_PASSWORD + '.SUCCESS']: (prevState) => {
        return {
            ...prevState,
        }
    },
    [SET_PREFILL_DATA]: (prevState, {payload}) => {
        return {
            ...prevState,
            prefillData: payload,
        }
    },
    [SET_IS_NEW_PATIENT_FLOW]: (prevState, {payload}) => {
        return {
            ...prevState,
            isNewPatientFlow: payload,
        }
    },
    [SET_IS_SIGN_IN_ON_PATIENT_FLOW]: (prevState, {payload}) => {
        return {
            ...prevState,
            isSignInOnNewPatientFlow: payload,
        }
    },
    [SET_IS_AUTH_IN_STAGING]: (prevState, {payload}) => {
        return {
            ...prevState,
            isAuthInStaging: payload,
        }
    },
    [SET_TYPE_AUTHORIZATION]: (prevState, {payload}) => {
        return {
            ...prevState,
            typeAuthorization: payload,
        }
    },
    [SET_IS_REGISTRATION_FLOW]: (prevState, {payload}) => {
        return {
            ...prevState,
            isRegistrationFlow: payload,
        }
    },
    [GET_PATIENT_INSURANCE + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            user: {
                ...prevState?.user,
                insurances: payload.patient_insurances,
            },
        }
    },
}

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
)
