import {withStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

const StyledSelect = withStyles(() => ({
    root: {
        border: '1px solid #E1E6EC',
        background: '#FFFFFF',
        boxSizing: 'border-box',
        boxShadow: '0px 6px 16px rgba(0, 115, 221, 0.1)',
        borderRadius: 8,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        whiteSpace: 'break-spaces',
    },
    icon: {
        cursor: 'pointer',
        marginRight: 16,
    },
    select: {
        backgroundColor: '#FFFFFF !important',
        borderRadius: '8px !important',
    }
}))(Select)

export default StyledSelect;
