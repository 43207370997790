import {createReducer} from '../../Helpers/redux';

import {IPayloadReducer, ITenantState} from "../types/tenant";
import {ITenantTypeAction} from "../constants/tenant";

const initialState: ITenantState = {
    tenant: {},
    tenants: [],
    status: false,
    slug: window.location.pathname.split('/')[1],
    error: null,
    insurance_id: null,
    tenantQuestionnaires: null,
}

const handlers = {
    [ITenantTypeAction.GET_TENANTS]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['tenants']>) => {
        return {
            ...prevState,
            tenants: payload
        }
    },
    [ITenantTypeAction.GET_TENANT_SUCCESS]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['tenant']>) => {
        return {
            ...prevState,
            ...payload,
            slug: prevState.slug,
            status: true,
            error: null
        }
    },
    [ITenantTypeAction.GET_TENANT_FAILURE]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['error']>) => {
        return {
            ...prevState,
            error: payload
        }
    },
    [ITenantTypeAction.CLEAR_TENANT]: (prevState: ITenantState) => {
      return {
          ...prevState,
          tenant: {},
          status: false,
          slug: window.location.pathname.split('/')[1],
          error: null,
          insurance_id: null,
      }
    },
    [ITenantTypeAction.SET_SLUG]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['slug']>) => {
        localStorage.setItem('slug', JSON.stringify(payload))
        return {
            ...prevState,
            slug: payload
        }
    },
    [ITenantTypeAction.SET_INSURANCE_ID]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['insurance_id']>) => {
        return {
            ...prevState,
            insurance_id: payload
        }
    },
    [ITenantTypeAction.GET_TENANT_QUESTIONNAIRES_SUCCESS]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['tenantQuestionnaires']>) => {
        return {
            ...prevState,
            tenantQuestionnaires: payload,
        }
    }
}

export default createReducer(initialState, handlers)