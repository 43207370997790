import React, {FC} from 'react'

import './StyledButton.scss'

type IProps = {
    title: string;
    handleClick: () => void;
    type?: string;
    fullWidth?: boolean;
    ButtonProps?: any;
    disable?: boolean;
    className?: string;
    style?: any;
}


const StyledButton: FC<IProps> = ({title, handleClick, type, fullWidth, ButtonProps, disable, className, style}: IProps) => {
    return (
        <button
            className={`styledButton ${type ? type : 'Primary'} ${fullWidth ? 'FullWidth' : ''} ${disable ? 'Disable' : ''} ${className ? className : ''}`}
            style={style ? style : {}}
            onClick={disable ? () => {} : handleClick}
            type={'button'}
        >
            <div className={`styledButton--startAdornment ${!ButtonProps?.startAdornment && !ButtonProps?.endAdornment && 'empty' } `}>{ButtonProps?.startAdornment}</div>
            <p>{title}</p>
            <div className={`styledButton--endAdornment ${!ButtonProps?.startAdornment && !ButtonProps?.endAdornment && 'empty' }`}>{ButtonProps?.endAdornment}</div>
        </button>
    )
}

export default React.memo(StyledButton);