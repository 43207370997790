import {IMethodRequest, IResponseCustomAction} from "./index";

export const GET_PROVIDERS = 'providers/GET_PROVIDERS'
export const fetchProviders = (appointment_type_id?: number, questionnaire_id?: number): IResponseCustomAction<string> => {
  return {
    method: IMethodRequest.GET,
    endpoint: 'providers',
    qsParams: {
      appointment_type_id,
      questionnaire_id,
    },
    type: GET_PROVIDERS
  }
}