import React, {useCallback, useRef} from 'react'
import {Modal} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch, useSelector} from "react-redux";
import {getWebCameraModal} from "../../../Redux/selectors/modal";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../../../styles";
import Webcam from "react-webcam";
import {StyledButton} from "../../buttons";
import typesButton from "../../../Helpers/constants/typesStyledButton.costants";
import {closeWebCameraModal} from "../../../Redux/actions/modal";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalWrapper: {
        backgroundColor: styles.backgroundColor,
        boxSizing: 'border-box',
        maxWidth: 493,
        maxHeight: 550,
        overflowY: 'auto',
        width: '100%',
        borderRadius: 8,
        padding: 22,
        [theme.breakpoints.down('xs')]: {
            margin: 15,
            padding: 10,
        },
    },
    modalHead: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    modalCross: {
        color: styles.textColor,
        height: 24,
        width: 24,
        cursor: 'pointer',
    },
    modalContent: {
        padding: '0 26px 26px 34px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px 10px 10px'
        }
    },
    modalName: {
        textAlign: 'center',
        padding: '10px 0',
        fontSize: 18,
        fontWeight: 800
    },
    modalButtons: {
        paddingTop: 22,
        display: 'flex',
        justifyContent: 'space-between'
    },
}));

const ModalForWebCamera = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const webcamRef = useRef(null);
    const webCameraModal = useSelector(getWebCameraModal)
    const closeModal = useCallback(() => {
        dispatch(closeWebCameraModal())
    }, [])
    const onContinue = useCallback(() => {
        const imgSrc = webcamRef.current.getScreenshot()
        webCameraModal.onContinue(imgSrc)
    }, [webCameraModal, webcamRef])

    return (
        <div>
            {webCameraModal.isOpen &&
            <Modal
                open={webCameraModal.isOpen}
                onClose={() => {}}
                className={classes.modal}
            >
                <div className={classes.modalWrapper}>
                    <div className={classes.modalHead}>
                        <CloseIcon className={classes.modalCross} onClick={closeModal} />
                    </div>
                    <div className={classes.modalContent}>
                        <h4 className={classes.modalName}>{webCameraModal.title}</h4>
                        <Webcam
                            audio={false}
                            height={720}
                            style={{position: "initial", width: '100%', height: '100%', margin: '10px 0 10px 0'}}
                            screenshotFormat="image/jpeg"
                            width={1280}
                            videoConstraints={videoConstraints}
                            ref={webcamRef}
                        >
                            {() => (
                                <div className={classes.modalButtons}>
                                <StyledButton title={'Cancel'} type={typesButton.Secondary} handleClick={() => {}}/>
                                <StyledButton title={'Continue'} type={typesButton.Primary} handleClick={onContinue}/>
                                </div>
                            )}
                        </Webcam>
                    </div>
                </div>
            </Modal>
            }
        </div>
    );
}

export default ModalForWebCamera;