import React, {FC} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../assets/logo.svg';


const useStyles = makeStyles(() => ({
    nameIconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

type IProps = {
    name: string;
    img: string;
    nameIconClass: string;
    imgClass: string;
}

const LetterContainer: FC<IProps> = ({
    name,
    img,
    nameIconClass,
    imgClass
}: IProps) => {
    const classes = useStyles();

    return img ? <img src={img} alt={name} className={`${imgClass}`} /> : (
        <div className={classes.nameIconContainer}>
            <div className={nameIconClass}>
                <img src={logo} />
            </div>
        </div>
    );
};

export default React.memo(LetterContainer);