import authHome from './containers/authHome.json';
import specialist from './containers/specialist.json';
import home from './containers/home.json';
import login from './containers/login.json';
import findRecord from './containers/findRecord.json';
import signup from './containers/signup.json';
import carePlan from './containers/carePlan.json';
import appointment from './containers/appointment.json';
import confirmation from './containers/confirmation.json';
import insurance from './containers/insurance.json';
import manage from './containers/manage.json';
import summary from './containers/summary.json';
import checkout from './containers/checkout.json';
import profile from './containers/profile.json';
import resetPass from './containers/resetPass.json';
import success from './containers/success.json';
import signin from './containers/signin.json'

import modal from './components/modal.json';
import inputs from './components/inputs.json';
import sidebar from './components/sidebar.json';
import chat from './components/chat.json';
import field from './components/field.json';
import button from './components/button.json';

import visits from './lists/visits.json';

import validation from './validation.json';
import global from './global.json';

export default {
    authHome,
    specialist,
    global,
    home,
    login,
    modal,
    findRecord,
    inputs,
    field,
    button,
    signup,
    validation,
    carePlan,
    appointment,
    visits,
    confirmation,
    insurance,
    manage,
    sidebar,
    summary,
    checkout,
    profile,
    resetPass,
    success,
    chat,
    signin
}
