import React, {FC, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';

import { styles } from '../../../styles';

import { getModal } from '../../../Redux/selectors/modal';

import { closeModal } from '../../../Redux/actions/modal';
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalWrapper: {
        backgroundColor: styles.backgroundColor,
        boxSizing: 'border-box',
        maxWidth: 493,
        maxHeight: 550,
        overflowY: 'auto',
        width: '100%',
        borderRadius: 8,
        padding: 22,
        [theme.breakpoints.down('xs')]: {
            margin: 15,
            padding: 10,
        },
    },
    modalHead: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    modalCross: {
        color: styles.textColor,
        height: 24,
        width: 24,
        cursor: 'pointer',
    },
    modalContent: {
        padding: '0 26px 26px 34px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px 10px 10px'
        }
    },
    modalIcon: {
        width: 35,
        height: 35
    },
    paragraph: {
        fontSize: 15,
        lineHeight: '19px'
    },
    modalName: {
        padding: '10px 0',
        fontSize: 18,
        fontWeight: 800
    },
    modalButtons: {
        paddingTop: 22,
        display: 'flex',
        justifyContent: 'space-between'
    },
    modalButton: {
        paddingRight: 16,
        width: '100%'
    },
    childrenContainer: {
        paddingTop: 20
    }
}));

const MainModal: FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const modal = useSelector(getModal);

    useEffect(() => {
        dispatch(closeModal())
    }, [location.pathname])

    const handleSuccess = () => {
        modal?.onSuccess && modal.onSuccess();
        dispatch(closeModal());
    }

    const onCloseModal = () => {
        dispatch(closeModal())
    }

    return (
        <div>
            {modal.isOpen &&
                <Modal
                    open={modal.isOpen}
                    onClose={onCloseModal}
                    className={classes.modal}
                >
                    <div className={classes.modalWrapper}>
                        <div className={classes.modalHead}>
                            <CloseIcon className={classes.modalCross} onClick={onCloseModal} />
                        </div>
                        <div className={`${classes.modalContent} ${modal?.contentClass ? modal.contentClass : ''}`}>
                            {modal.icon &&
                                <img src={modal.icon} alt='modal icon' className={classes.modalIcon} />
                            }
                            <h4 className={classes.modalName}>{modal.title}</h4>
                            {modal.description &&
                                <p className={classes.paragraph}>{modal.description}</p>
                            }
                            {modal.customChildren ?
                                (
                                    <div className={classes.childrenContainer}>
                                        <modal.customChildren />
                                    </div>
                                ) : (
                                    <div className={classes.modalButtons}>
                                        {modal.cancelText &&
                                            <div className={classes.modalButton}>
                                                <button
                                                    className='button modal-cancel-button'
                                                    onClick={modal.activeLeft ? () => handleSuccess() : () => onCloseModal()}
                                                >
                                                    {modal.activeLeft ? modal.submitText : modal.cancelText}
                                                </button>
                                            </div>
                                        }
                                        <button
                                            className='button modal-submit-button'
                                            onClick={modal.activeLeft ? () => onCloseModal() : () => handleSuccess()}
                                        >
                                            {modal.activeLeft ? modal.cancelText : modal.submitText}
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal>
            }
        </div>
    );
};

export default React.memo(MainModal);
