import {routes} from '../../../../RoutesConst';

export const navbarList = [
    routes.home,
    routes.profile.home,
    routes.profile.profileBasicInformation,
    routes.profile.profileEmailInformation,
    routes.profile.profilePhoneInformation,
    routes.profile.profilePasswordInformation,
    routes.profile.profileVerify,
    routes.chooseAppointment,
    routes.chooseSpecificType,
    routes.additionalSpecificType,
    routes.appointment,
    routes.confirmation,
    routes.summary,
    routes.insuranceProvider,
    routes.doctorProvider,
    routes.payAddCard,
    routes.paymentPage,
    routes.paymentCards,
    routes.tenants,
    routes.userAppointments,
    routes.userAppointment,
    routes.userPrescription,
    routes.insuranceFlow.home,
    routes.insuranceFlow.coverages,
    routes.insuranceFlow.workplaceAccident,
    routes.insuranceFlow.automotiveAccident,
    routes.insuranceFlow.otherBenefits,
    routes.insuranceFlow.courtMandated,
    routes.insuranceFlow.homeInsurance,
    routes.insuranceFlow.takingPhoto.home,
    routes.insuranceFlow.takingPhoto.front,
    routes.insuranceFlow.takingPhoto.back,
    routes.insuranceFlow.describing,
    routes.insuranceFlow.photos,
    routes.insuranceFlow.reviewInsurance,
    routes.insuranceFlow.reviewCoverage,
    routes.support.errorReport,
]
