import { takeLatest } from 'redux-saga/effects'

import { SIGN_IN, LOG_OUT, SIGN_UP } from '../../../actions/auth'

import onSignIn from './onSignIn'
import onLogOut from './onLogOut'
import onSingUp from './onSignUp';

function* authSaga () {
  yield takeLatest(SIGN_IN + '.SUCCESS', onSignIn);
  yield takeLatest(LOG_OUT, onLogOut);
  yield takeLatest(SIGN_UP + '.SUCCESS', onSingUp);
}

export default authSaga;
