export default Object.freeze({
    GET_AVAILABLE_APPOINTMENTS: 'appointments/GET_AVAILABLE_APPOINTMENTS',
    GET_AVAILABLE_APPOINTMENTS_SUCCESS: 'appointments/GET_AVAILABLE_APPOINTMENTS.SUCCESS',
    GET_AVAILABLE_APPOINTMENTS_FAILURE: 'appointments/GET_AVAILABLE_APPOINTMENTS.FAILURE',

    GET_APPOINTMENTS: 'appointments/GET_APPOINTMENTS',
    GET_APPOINTMENTS_SUCCESS: 'appointments/GET_APPOINTMENTS.SUCCESS',
    GET_APPOINTMENTS_FAILURE: 'appointments/GET_APPOINTMENTS.FAILURE',

    GET_MONTH_APPOINTMENTS: 'appointments/GET_MONTH_APPOINTMENTS',
    GET_MONTH_APPOINTMENTS_SUCCESS: 'appointments/GET_MONTH_APPOINTMENTS.SUCCESS',
    GET_MONTH_APPOINTMENTS_FAILURE: 'appointments/GET_MONTH_APPOINTMENTS.FAILURE',

    GET_PRESCRIPTIONS: 'appointments/GET_PRESCRIPTIONS',
    GET_PRESCRIPTIONS_SUCCESS: 'appointments/GET_PRESCRIPTIONS.SUCCESS',
    GET_PRESCRIPTIONS_FAILURE: 'appointments/GET_PRESCRIPTIONS.FAILURE',

    BOOK_APPOINTMENT: 'appointments/BOOK_APPOINTMENT',
    BOOK_APPOINTMENT_SUCCESS: 'appointments/BOOK_APPOINTMENT.SUCCESS',
    BOOK_APPOINTMENT_FAILURE: 'appointments/BOOK_APPOINTMENT.FAILURE',

    RESET_BOOKING : 'appointments/RESET_BOOKING',
    RESET_BOOKING_SUCCESS : 'appointments/RESET_BOOKING.SUCCESS',
    RESET_BOOKING_FAILURE : 'appointments/RESET_BOOKING.FAILURE',

    ADD_INSURANCE_TO_BOOKED_APPOINTMENT : 'appointments/ADD_INSURANCE_TO_BOOKED_APPOINTMENT',
    ADD_INSURANCE_TO_BOOKED_APPOINTMENT_SUCCESS : 'appointments/ADD_INSURANCE_TO_BOOKED_APPOINTMENT.SUCCESS',
    ADD_INSURANCE_TO_BOOKED_APPOINTMENT_FAILURE : 'appointments/ADD_INSURANCE_TO_BOOKED_APPOINTMENT.FAILURE',

    REMOVE_INSURANCE_LINKED_APPOINTMENT : 'appointments/REMOVE_INSURANCE_LINKED_APPOINTMENT',
    REMOVE_INSURANCE_LINKED_APPOINTMENT_SUCCESS : 'appointments/REMOVE_INSURANCE_LINKED_APPOINTMENT.SUCCESS',
    REMOVE_INSURANCE_LINKED_APPOINTMENT_FAILURE : 'appointments/REMOVE_INSURANCE_LINKED_APPOINTMENT.FAILURE',

    CANCEL_PRESCRIPTION : 'appointments/CANCEL_PRESCRIPTION',
    CANCEL_PRESCRIPTION_SUCCESS : 'appointments/CANCEL_PRESCRIPTION_SUCCESS',
    CANCEL_PRESCRIPTION_FAILURE : 'appointments/CANCEL_PRESCRIPTION_FAILURE',

    RESCHEDULE_APPOINTMENT : 'appointments/RESCHEDULE_APPOINTMENT',
    RESCHEDULE_APPOINTMENT_SUCCESS : 'appointments/RESCHEDULE_APPOINTMENT.SUCCESS',
    RESCHEDULE_APPOINTMENT_FAILURE : 'appointments/RESCHEDULE_APPOINTMENT.FAILURE',

    FREEZE_APPOINTMENT : 'appointments/FREEZE_APPOINTMENT',
    FREEZE_APPOINTMENT_SUCCESS : 'appointments/FREEZE_APPOINTMENT.SUCCESS',
    FREEZE_APPOINTMENT_FAILURE : 'appointments/FREEZE_APPOINTMENT.FAILURE',

    GET_APPOINTMENT_TYPES : 'appointments/GET_APPOINTMENT_TYPES',
    GET_APPOINTMENT_TYPES_SUCCESS : 'appointments/GET_APPOINTMENT_TYPES.SUCCESS',
    GET_APPOINTMENT_TYPES_FAILURE : 'appointments/GET_APPOINTMENT_TYPES.FAILURE',

    GET_APPOINTMENT_PRICE: 'appointments/GET_APPOINTMENT_PRICE',
    GET_APPOINTMENT_PRICE_SUCCESS : 'appointments/GET_APPOINTMENT_PRICE.SUCCESS',
    GET_APPOINTMENT_PRICE_FAILURE : 'appointments/GET_APPOINTMENT_PRICE.FAILURE',

    SET_CURRENT_DATE: 'appointments/SET_CURRENT_DATE',

    CLEAR_APPOINTMENTS_STATE: 'appointments/CLEAR_APPOINTMENTS_STATE'
})
