import { select } from 'redux-saga/effects';

import { getSlug } from '../../../selectors/tenant';

function* onLogOut(action) {
  localStorage.removeItem('persist:auth')
  const slug = yield select(getSlug);
  action.history.push(`/${slug}`)

  yield true;
};

export default onLogOut;
