import { createReducer } from '../../Helpers/redux';

import {GET_PROVIDERS} from '../actions/providers';

import {IPayloadReducer, IProvidersState} from "../types/providers";

const initialState: IProvidersState = {
    providers: [],
    error: null,
}

const handlers = {
    [GET_PROVIDERS + '.SUCCESS']: (prevState: IProvidersState, { payload }: IPayloadReducer<IProvidersState['providers']>) => {
        return {
            ...prevState,
            ...payload,
            error: null
        }
    },
    [GET_PROVIDERS + '.FAILURE']: (prevState: IProvidersState, { payload }: IPayloadReducer<IProvidersState['error']>) => {
        return {
            ...prevState,
            error: payload
        }
    },
}

export default createReducer(initialState, handlers);