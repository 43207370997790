import { createReducer } from '../../Helpers/redux';
import storage from 'redux-persist/lib/storage'
import { VERIFY_BY_PHONE, CLEAR_VERIFICATION_STATE, SET_FORM_FROM, VERIFY_GOOGLE } from '../actions/verification';
import {NotificationService} from "../../services";
import {persistReducer} from "redux-persist";

const initialState = {
    from: 'login',
    status: false,
    error: null,
}

const persistConfig = {
    key: 'verification',
    storage,
    blacklist: ['status', 'error'],
}

const handlers = {
    [VERIFY_BY_PHONE]: (prevState) => {
        return {
            status: false,
        ...prevState,
        verifyError: null
        }
    },
    [VERIFY_BY_PHONE + '.SUCCESS']: (prevState, { payload }) => {
        NotificationService.success(undefined, 'Welcome to Flight');
        return {
            ...prevState,
            token: payload.user?.api_token,
            status: true,
            user: payload.user,
            verifyError: null
        }
    },
    [VERIFY_BY_PHONE + '.FAILURE']: (prevState, { payload }) => {
        NotificationService.error(undefined, 'Failure authorization');
        return {
            ...prevState,
            status: false,
            verifyError: payload
        }
    },  
    [CLEAR_VERIFICATION_STATE]: () => {
        return {
            ...initialState
        }
    },
    [SET_FORM_FROM]: (prevState, {payload}) => {
        return {
            ...prevState,
            from: payload.from,
        }
    },
    [VERIFY_GOOGLE]: (prevState) => {
        return {
            ...prevState,

        }
    },
    [VERIFY_GOOGLE + '.SUCCESS']: (prevState, {payload}) => {
        return {
            ...prevState,
            error: payload?.message === 'Success' ? {message: 'User with passed params does not exist'} : {...payload} ,
        }
    },
    [VERIFY_GOOGLE + '.FAILURE']: (prevState) => {
        return {
            ...prevState,
            error: null,
        }
    },
}

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
)