import React, {lazy, Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import './localization';

import {routes} from './RoutesConst';

import {getTenant, setSlug} from './Redux/actions/tenant';
import {changeOrder} from './Redux/actions/order';
import {logOut} from "./Redux/actions/auth";

import {getIsAuth, getIsAuthInStaging} from './Redux/selectors/auth';
import {getErrorOrSuccessScreen} from "./Redux/selectors/modal";
import {getSlug} from './Redux/selectors/tenant';

import ScrollToTop from './Helpers/scrollToTop';
import {MainModal, ModalCancelAppointment, ModalChangingInsurance, ModalForWebCamera} from './components/modals'
import Loading from './components/helpers/loading';
import ContentWrapper from './components/wrappers/contentWrapper';
import ErrorOrSuccessPage from "./containers/errorOrSuccessPage";
import AuthStagingPage from "./containers/authStagingPage";

import {useInactivityTime, useMount, usePrevious} from "./hooks";

const App = ({history}) => {
    const historyRoute = useHistory();
    const dispatch = useDispatch();
    const slug = useSelector(getSlug);
    const isAuth = useSelector(getIsAuth);
    const errorOrSuccessScreen = useSelector(getErrorOrSuccessScreen)
    const prevSlug = usePrevious(slug);
    const isAuthInStaging = useSelector(getIsAuthInStaging);

    useInactivityTime(isAuth ? () => {
        dispatch(logOut(history));
        dispatch(changeOrder({appointment: {}, doctor: {}, paymentStatus: { status: '' }}));
        historyRoute.push(`/${slug}/`);
    } : () => {});

    useMount(() => dispatch(setSlug(window.location.pathname.split("/")[1])))

    useEffect(() => {
        if (slug) {
            dispatch(getTenant(slug))
        }
        if (prevSlug && prevSlug !== slug) {
            dispatch(changeOrder({appointment: {}, doctor: {}}));
        }
    }, [slug]);

    console.log(process.env.NODE_ENV)

    return (
        <ConnectedRouter history={history}>
            <Router>
                <div className="App">
                    <Suspense fallback={<Loading/>}>
                        {process.env.NODE_ENV === 'development' && !isAuthInStaging && (
                            <AuthStagingPage />
                        )}
                        <ModalForWebCamera/>
                        <MainModal/>
                        <ModalChangingInsurance/>
                        <ModalCancelAppointment/>
                        <ScrollToTop>
                            {!isAuth ? (
                                <Switch>
                                    {errorOrSuccessScreen.isOpen && (<ContentWrapper><ErrorOrSuccessPage/></ContentWrapper>)}
                                    <Route path={routes.home} exact
                                           component={lazy(() => import('./containers/notAuth/home'))}/>
                                    <ContentWrapper
                                        container
                                        component="main"
                                    >
                                        <Switch>
                                            <Route path={routes.tenants} exact
                                                   component={lazy(() => import('./containers/introducePage'))} />
                                            <Route path={routes.recoveryPassword} exact
                                                   component={lazy(() => import('./containers/recoveryPasswordPage'))} />
                                            <Route path={routes.verifyEmail} exact
                                                   component={lazy(() => import('./containers/verifyEmailPage'))} />
                                            <Route path={routes.confirmation} exact
                                                   component={lazy(() => import('./containers/auth/confirmation'))}/>
                                            <Route path={routes.signin} exact
                                                   component={lazy(() => import('./containers/auth/signIn'))}/>
                                            <Route path={routes.login} exact
                                                   component={lazy(() => import('./containers/notAuth/login'))}/>
                                            <Route path={routes.signup} exact
                                                   component={lazy(() => import('./containers/notAuth/signup'))}/>
                                            <Route path={routes.verify} exact
                                                   component={lazy(() => import('./containers/notAuth/verifyCode'))} />
                                            <Route path={routes.coincidencePage} exact
                                                   component={lazy(() => import('./containers/coincidencePage'))} />
                                            <Route path={routes.appointment} exact
                                                   component={lazy(() => import('./containers/auth/appointment'))}/>
                                            <Route path={routes.insuranceProvider} exact
                                                   component={lazy(() => import('./containers/insuranceProvider'))}/>
                                            <Route path={routes.doctorProvider} exact
                                                   component={lazy(() => import('./containers/doctorProvider'))}/>
                                            <Route path={routes.chooseAppointment} exact
                                                   component={lazy(() => import('./containers/chooseAppointmentTypePage'))}/>
                                            <Route path={routes.chooseSpecificType} exact
                                                   component={lazy(() => import('./containers/chooseSpecificTypePage'))}/>
                                            <Route path={routes.additionalSpecificType} exact
                                                   component={lazy(() => import('./containers/additionalSpecificTypePage'))}/>
                                            <Route path={routes.support.home}
                                                   component={lazy(() => import('./containers/support'))} />
                                            <Route path='*'>
                                                <Redirect to={`/${slug}`}/>
                                            </Route>
                                        </Switch>
                                    </ContentWrapper>
                                </Switch>
                            ) : (
                                <ContentWrapper
                                    container
                                    component="main"
                                >
                                    <Switch>
                                        {errorOrSuccessScreen.isOpen && (<ErrorOrSuccessPage/>)}
                                        <Route path={routes.tenants} exact
                                               component={lazy(() => import('./containers/introducePage'))} />
                                        <Route path={routes.userAppointments} exact
                                               component={lazy(() => import('./containers/appointmentsPage'))} />
                                        <Route path={routes.userAppointment} exact
                                               component={lazy(() => import('./containers/editAppointmentPage'))} />
                                        <Route path={routes.userPrescription} exact
                                               component={lazy(() => import('./containers/editPrescriptionPage'))} />
                                        <Route path={routes.confirmation} exact
                                               component={lazy(() => import('./containers/auth/confirmation'))}/>
                                        <Route path={routes.summary} exact
                                               component={lazy(() => import('./containers/auth/summary'))}/>
                                        <Route path={routes.chooseAppointment} exact
                                               component={lazy(() => import('./containers/chooseAppointmentTypePage'))}/>
                                        <Route path={routes.chooseSpecificType} exact
                                               component={lazy(() => import('./containers/chooseSpecificTypePage'))}/>
                                        <Route path={routes.additionalSpecificType} exact
                                               component={lazy(() => import('./containers/additionalSpecificTypePage'))}/>
                                        <Route path={routes.appointment} exact
                                               component={lazy(() => import('./containers/auth/appointment'))}/>
                                        <Route path={routes.insuranceProvider} exact
                                               component={lazy(() => import('./containers/insuranceProvider'))}/>
                                        <Route path={routes.profile.home}
                                               component={lazy(() => import('./containers/auth/profile'))}/>
                                        <Route path={routes.doctorProvider} exact
                                               component={lazy(() => import('./containers/doctorProvider'))}/>
                                        <Route path={routes.paymentPage} exact
                                               component={lazy(() => import('./containers/paymentPage'))} />
                                        <Route path={routes.paymentCards} exact
                                               component={lazy(() => import('./containers/paymentCardsPage'))} />
                                        <Route path={routes.payAddCard} exact
                                               component={lazy(() => import('./containers/addNewCreditCard'))} />
                                        <Route path={routes.verifyEmail} exact
                                               component={lazy(() => import('./containers/verifyEmailPage'))} />
                                        <Route path={routes.recoveryPassword} exact
                                               component={lazy(() => import('./containers/recoveryPasswordPage'))} />
                                        <Route path={routes.insuranceFlow.home}
                                               component={lazy(() => import('./containers/insuranceFlow'))} />
                                        <Route path={routes.support.home}
                                               component={lazy(() => import('./containers/support'))} />
                                        <Route path='*'>
                                            <Redirect to={slug ? `/${slug}/choose-appointment-type` : `/`}/>
                                        </Route>
                                    </Switch>
                                </ContentWrapper>
                            )}
                        </ScrollToTop>
                        <ToastContainer autoClose={5000}/>
                    </Suspense>
                </div>
            </Router>
        </ConnectedRouter>
    );
};

export default App;
