import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import auth from './auth';
import modal from './modal';
import order from './order';
import verification from './verification';
import locations from './locations';
import providers from './providers';
import appointments from './appointments';
import resetPassword from './resetPassword';
import tenant from './tenant'
import payments from './payments';
import users from './users'

const rootReducer = (history) => combineReducers({
  auth,
  modal,
  order,
  users,
  tenant,
  payments,
  locations,
  providers,
  appointments,
  verification,
  resetPassword,
  router: connectRouter(history)
});

export default rootReducer;
