import React, {FC} from "react";
import classNames from "classnames";

import './styles.scss'

type IProps = {
    separatorText?: string;
    className?: string;
}

const StyledHorizontalBreak: FC<IProps> = ({separatorText, className}: IProps) => {

    return (
        <div className={classNames('line-wrapper', className ? className : '')}>
            <hr/>
            <p>{separatorText ? separatorText : 'or'}</p>
            <hr/>
        </div>
    )
}

export default React.memo(StyledHorizontalBreak);