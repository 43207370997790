import React, {useCallback} from "react";
import AppleLogin from "react-apple-login";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import './styles.scss';

import {verifyAppleByToken} from "../../../Redux/actions/auth";

import {getOrder} from "../../../Redux/selectors/order";
import {getIsNewPatientFlow} from "../../../Redux/selectors/auth";
import {getActiveInsuranceId, getSlug} from "../../../Redux/selectors/tenant";

import AppleIcon from "../../../assets/apple.svg";

const CLIENT_ID = "com.flightHealth.service";

const AppleLoginButton = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const slug = useSelector(getSlug);
    const order = useSelector(getOrder);
    const insuranceId = useSelector(getActiveInsuranceId);
    const isNewPatientFlow = useSelector(getIsNewPatientFlow);

    const responseApple = useCallback((response) => {
        if (response?.authorization) {
            const {authorization: {id_token, code}} = response;
            dispatch(verifyAppleByToken(id_token, code, order, insuranceId, isNewPatientFlow, history, slug))
        }
    }, [slug, order, insuranceId, isNewPatientFlow])

    return (
        <AppleLogin
            responseMode={"form_post"}
            responseType={"code"}
            scope="name email"
            callback={responseApple}
            clientId={CLIENT_ID}
            redirectURI={location.origin}
            usePopup={true}
            render={(renderProps) => (
                <button
                    type="button"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="social-media-button apple"
                >
                    <img src={AppleIcon} alt="apple"/>
                    Continue with Apple
                </button>
            )}
            designProp={{
                height: 50,
                color: "black",
                border: false,
                type: "",
                border_radius: 15,
                scale: 1,
            }}
        />
    );
}

export default React.memo(AppleLoginButton);
