import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

const BirthField = withStyles(() => ({
    root: {
        width: '100%',
        marginTop: '6px',
        paddingBottom: '10px',
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            backgroundColor: 'rgb(248,248,248)',
            textAlign: 'center',
        },
        '& fieldset':{
            border: 'none'
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border: '2px solid rgb(138, 48, 226)',
            },
        },
    },
}))(TextField)

export default BirthField;
