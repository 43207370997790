import axios from "axios";
import {apiConfigs} from "../configs/api";
import {refreshTokenAction} from "./auth";
import {changeOrder, setEditOrder} from "./order";

import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {IMethodRequest, IResponseCustomAction} from "./index";
import {IState} from "../types";
import {AnyAction} from "redux";
import appointmentsConstants from '../constants/appointments'

export const fetchAvailvableApp = (startDate: string, endDate: string, providerIds: number, appTypeId: number, locationIds: number, questionnaireId?: number): IResponseCustomAction<string> => {
  return {
    method: IMethodRequest.GET,
    endpoint: `appointments/upcoming?date_from=${startDate}&date_to=${endDate}&provider_ids=${providerIds}&appointment_type_id=${appTypeId}&location_ids=${locationIds}&questionnaire_id=${questionnaireId}`,
    type: appointmentsConstants.GET_AVAILABLE_APPOINTMENTS,
  }
}

export const fetchMonthApp = (startDate: string, endDate: string, providerIds: number, appTypeId: number, locationIds: number, questionnaireId?: number): IResponseCustomAction<string> => {
  return {
    method: IMethodRequest.GET,
    endpoint: `appointments/upcoming?date_from=${startDate}&date_to=${endDate}&provider_ids=${providerIds}&appointment_type_id=${appTypeId}&location_ids=${locationIds}&questionnaire_id=${questionnaireId}`,
    type: appointmentsConstants.GET_MONTH_APPOINTMENTS,
  }
}

export const getUserAppointment = (slug: string, appointmentId: number): ThunkAction<void, IState, unknown, AnyAction> => async (dispatch: ThunkDispatch<IState, unknown, AnyAction>) => {
  const localStorageUserData = localStorage.getItem('persist:auth');
  const userData = JSON.parse(String(localStorageUserData));
  const { token } = userData;
  try {
    const res = (await axios.get(`https://api.${apiConfigs.hostUrl}appointments?slug=${slug}`, {
      headers: {Authorization: `Bearer ${JSON.parse(token)}`}
    }))
    const currentAppointment = res.data.appointments.find(appointment => appointment?.id === appointmentId);
    dispatch(setEditOrder({...currentAppointment, fee: Number(currentAppointment.fee)}))
    dispatch(changeOrder({type: { id: currentAppointment?.appointment_type_id }}))
  } catch (error) {
    if(error.response.data.message === 'Signature has expired') {
      dispatch(refreshToken(error.config, () => dispatch(getUserAppointment(slug, appointmentId))))
    }
  }
}

export const getUserAppointments = (slug: string): ThunkAction<void, IState, unknown, AnyAction> => async (dispatch: ThunkDispatch<IState, unknown, AnyAction>) => {
  const localStorageUserData = localStorage.getItem('persist:auth');
  const userData = JSON.parse(String(localStorageUserData));
  const { token } = userData;
  try {
    const resAppointments = (await axios.get(`https://api.${apiConfigs.hostUrl}appointments?slug=${slug}`, {
      headers: {Authorization: `Bearer ${JSON.parse(token)}`}
    }))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = [...resAppointments.data.appointments];
    dispatch(fetchAppointments(res))
  } catch (error) {
    if(error.response?.data?.message === 'Signature has expired') {
      dispatch(refreshToken(error.config, () => dispatch(getUserAppointments(slug))))
    }
  }
}

export const getUserPrescriptions = (slug: string): ThunkAction<void, IState, unknown, AnyAction> => async (dispatch: ThunkDispatch<IState, unknown, AnyAction>) => {
  const localStorageUserData = localStorage.getItem('persist:auth');
  const userData = JSON.parse(String(localStorageUserData));
  const { token } = userData;
  try {
    const resPrescriptions = (await axios.get(`https://${slug}.${apiConfigs.hostUrl}patient_prescription_requests`, {
      headers: {Authorization: `Bearer ${JSON.parse(token)}`}
    }))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = Array.isArray(resPrescriptions.data) ? [...resPrescriptions.data] : resPrescriptions.data.prescriptions;
    dispatch(fetchPrescriptions(res))
  } catch (error) {
    if(error.response?.data?.message === 'Signature has expired') {
      dispatch(refreshToken(error.config, () => dispatch(getUserPrescriptions(slug))))
    }
  }
}


export const refreshToken = (failedRequestConfig, callback): ThunkAction<void, IState, unknown, AnyAction> => async (dispatch: ThunkDispatch<IState, unknown, AnyAction>) => {
  const slug = window.location.pathname.split('/')[1];
  const localStorageUserData = localStorage.getItem('persist:auth');
  const userData = JSON.parse(String(localStorageUserData));
  const { token } = userData;
  const { refreshToken } = userData;
  const activeRefreshToken = JSON.parse(refreshToken)
  const activeToken = JSON.parse(token)
  axios.post(`https://${slug}.${apiConfigs.hostUrlV2}refresh_token?token=${activeToken}&refresh_token=${activeRefreshToken}`,)
      .then((res) => {
        const {token} = res.data;
        const {refresh_token} = res.data
        localStorage.setItem('persist:auth', JSON.stringify({
          ...userData,
          token: JSON.stringify(token),
          refreshToken: JSON.stringify(refresh_token),
        }))
        dispatch(refreshTokenAction({token, refresh_token}))
        failedRequestConfig.headers['Authorization'] = `Bearer ${token}`
        return axios(failedRequestConfig).then(callback)
      }).catch((error) => console.log(error))
}

export const fetchAppointments = (res): IResponseCustomAction<string, unknown> => ({
    type: appointmentsConstants.GET_APPOINTMENTS_SUCCESS,
    payload: res
})

export const fetchPrescriptions = (res): IResponseCustomAction<string, unknown> => ({
  type: appointmentsConstants.GET_PRESCRIPTIONS_SUCCESS,
  payload: res
})

export const bookApp = (id, appointment_type_id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/book`,
    query: {
      booking_note: '',
      appointment_type_id: appointment_type_id,
    },
    type: appointmentsConstants.BOOK_APPOINTMENT,
  }
}

export const resetBooking = (id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/cancel`,
    type: appointmentsConstants.RESET_BOOKING,
  }
}

export const cancelPrescription = (id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `patient_prescription_requests/${id}/cancel`,
    type: appointmentsConstants.CANCEL_PRESCRIPTION,
  }
}

export const freezeAppointment = (id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/freeze`,
    type: appointmentsConstants.FREEZE_APPOINTMENT,
  }
}

export const fetchAppointmentTypes = () => {
  return {
    method: IMethodRequest.GET,
    endpoint: `appointment_types`,
    type: appointmentsConstants.GET_APPOINTMENT_TYPES,
  }
}

export const rescheduleAppointment = (id, reschedule_to_id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/reschedule`,
    type: appointmentsConstants.RESCHEDULE_APPOINTMENT,
    query: {
      reschedule_to_id: reschedule_to_id,
      booking_note: '',
    },
  }
}

export const getAppointmentPrice = (id, reschedule_to_id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/check_reschedule`,
    type: appointmentsConstants.GET_APPOINTMENT_PRICE,
    query: {
      reschedule_to_id: reschedule_to_id,
      booking_note: '',
    },
  }
}

export const setCurrentDate = (currentDate) => {
  return {
    type: appointmentsConstants.SET_CURRENT_DATE,
    payload: currentDate,
  }
}

export const addInsuranceToBookedAppointment = (id, insurance_id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/add_insurance`,
    type: appointmentsConstants.ADD_INSURANCE_TO_BOOKED_APPOINTMENT,
    query: {
      insurance_id: insurance_id,
    },
  }
}

export const removeInsuranceLinkedAppointment = (id) => {
  return {
    method: IMethodRequest.PUT,
    endpoint: `appointments/${id}/remove_insurance`,
    type: appointmentsConstants.REMOVE_INSURANCE_LINKED_APPOINTMENT,
  }
}

export const clearAppointmentsState = () => ({type: appointmentsConstants.CLEAR_APPOINTMENTS_STATE})