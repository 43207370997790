import { createReducer } from '../../Helpers/redux';

import {
    PASSWORD_UPDATE
} from '../actions/resetPassword';

const initialState = {
    status: false,
    phone_number: '',
    error: null,
}

const handlers = {
    [PASSWORD_UPDATE + '.SUCCESS']: () => {
        return {
           status: true,
        }
    },
    [PASSWORD_UPDATE + '.FAILURE']: (prevState, { payload }) => {
        return {
           status: false,
           error: payload
        }
    },
}

export default createReducer(initialState, handlers);