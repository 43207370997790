export const PASSWORD_UPDATE = 'reset/PASSWORD_UPDATE'
export const passwordUpdate = (data) => {
  return {
    method: 'POST',
    endpoint: 'password_update',
    query: {
      ...data
    },
    type: PASSWORD_UPDATE,
  }
}