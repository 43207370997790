import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';

import {styles} from '../../styles';

const SearchField = withStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '6px',
        paddingBottom: '5px',
        minWidth: '300px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 0
        },
        '& .MuiInputBase-root': {
            fontWeight: 500
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0,0,0,0.5)',
            fontWeight: 500,
            fontFamily: 'Montserrat',
            '&.Mui-focused': {
                color: styles.mainColor
            }
        },
        '& fieldset': {
            boxShadow: '0 6px 13px 0 rgba(0,128,246,0.1)',
            border: 'none',
        },
        '& .MuiOutlinedInput-input': {
            fontFamily: 'Montserrat',
            borderRadius: '6px',
            padding: '13.5px 16px',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            borderRadius: '8px',
            '&.Mui-focused fieldset': {
                color: 'black',
                border: `1px solid ${styles.mainColor}`,
            },
            '&.Mui-focused': {
                backgroundColor: styles.backgroundColor,
            },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(20px, -6px) scale(0.65)',
            marginTop: '0px',
        },
        "& label": {
            marginTop: 0,
        },
        '& #email-label': {
            marginTop: 0,
            '&.MuiFormLabel-root.Mui-focused': {
                marginTop: 0,
            },
            '&.Mui-focused': {
                marginLeft: -4
            },
            '&.MuiFormLabel-filled': {
                marginLeft: -4
            },
        },
        '& #phone_number': {
            '& .MuiOutlinedInput-root': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            },
        },
        '& #phone_number-label': {
            marginTop: 4,
            '&.MuiFormLabel-root.Mui-focused': {
                marginTop: 0
            },
            '&.Mui-focused': {
                marginLeft: 4
            },
            '&.MuiFormLabel-filled': {
                marginLeft: 4
            },
        }

    },
}))(TextField)

export default SearchField
