import {createSelector} from 'reselect';

import {IState} from "../types";

const getState = (state: IState) => state;

export const getOrder = createSelector<any, any>(getState, (order) => order.order);

export const getEditedOrder = createSelector(getState, (order) => order.order.editedOrder);

export const getEditedPrescription = createSelector(getState, (order) => order.order.editedPrescription);

export const getIsEditBooking = createSelector(getState, (order) => order.order.isEditBooking);

export const getIsEdited = createSelector(getState, (order) => order.order.isEdited)

export const getAppointmentExpirationTime = createSelector(getState, (order) => order.order.appointmentExpirationTime)

export const getPrevAppointmentRoute = createSelector(getState, (order) => order.order.prevAppointmentRoute)

export const getIsFromProvider = createSelector(getState, (order) => order.order.isFromProvider)

export const getNestingAppointmentTypes = createSelector(getState, (order) => order.order.nestingAppointmentTypes)

