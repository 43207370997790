import persistReducer from 'redux-persist/es/persistReducer';
import {createReducer} from '../../Helpers/redux';
import storage from 'redux-persist/lib/storage'
import appointmentsConstants from '../constants/appointments'


import {
    CHANGE_ORDER,
    SET_APPOINTMENT_EXPIRATION_TIME, SET_EDIT_PRESCRIPTION,
    SET_EDITED_ORDER,
    SET_IS_EDIT_BOOKING,
    SET_IS_EDITED, SET_IS_FROM_PROVIDER, SET_PREV_APPOINTMENT_ROUTE
} from '../actions/order';

import {IOrderState} from "../types/order";
import {LOG_OUT} from "../actions/auth";
import {ITenantTypeAction} from "../constants/tenant";
import {IPayloadReducer, ITenantState} from "../types/tenant";

const initialState: IOrderState = {
    editedOrder: null,
    isEditBooking: false,
    isEdited: false,
    isFromProvider: false,
    type: {},
    doctor: {},
    appointment: {},
    nestingAppointmentTypes: [],
    insurance_id: null,
    paymentStatus: {
        status: '',
    },
    frontSideOfCard: {
        isStock: false,
        photo: null,
        isCapture: false,
        capturedPhoto: null,
    },
    backSideOfCard: {
        isStock: false,
        photo: null,
        isCapture: false,
        capturedPhoto: null,
    },
    appointmentExpirationTime: null,
    prevAppointmentRoute: 'doctor-provider',
    editedPrescription: null,
}

const persistConfig = {
    key: 'order',
    storage,
    whiteList: ['type', 'doctor', 'appointment', 'reason', 'specialty', 'nestingAppointmentTypes', 'insurance_id'],
}
const handlers = {
    [CHANGE_ORDER]: (prevState, {payload}) => {
        return {
            ...prevState,
            ...payload,
        }
    },
    [SET_EDITED_ORDER]: (prevState, {payload}) => {
        return {
            ...prevState,
            editedOrder: {
                ...payload,
            },
        }
    },
    [SET_EDIT_PRESCRIPTION]: (prevState, {payload}) => {
        return {
            ...prevState,
            editedPrescription: {
                ...payload,
            },
        }
    },
    [SET_IS_EDITED]: (prevState, {payload}) => {
        return {
            ...prevState,
            isEdited: payload,
        }
    },
    [SET_IS_EDIT_BOOKING]: (prevState, {payload}) => {
        return {
            ...prevState,
            isEditBooking: payload,
        }
    },
    [SET_APPOINTMENT_EXPIRATION_TIME]: (prevState, {payload}) => {
        return {
            ...prevState,
            appointmentExpirationTime: payload,
        }
    },
    [appointmentsConstants.GET_APPOINTMENT_PRICE_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            appointment: {
                ...prevState?.appointment,
                fee: payload,
            }
        }
    },
    [SET_PREV_APPOINTMENT_ROUTE]: (prevState, {payload}) => {
        return {
            ...prevState,
            prevAppointmentRoute: payload
        }
    },
    [ITenantTypeAction.SET_INSURANCE_ID]: (prevState: ITenantState, {payload}: IPayloadReducer<ITenantState['insurance_id']>) => {
        return {
            ...prevState,
            insurance_id: payload
        }
    },
    [SET_IS_FROM_PROVIDER]: (prevState, {payload}) => {
        return {
            ...prevState,
            isFromProvider: payload
        }
    },
    [LOG_OUT]: () => {
        return initialState;
    }

}

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
)