import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

const Check = withStyles(() => ({
    root: {
        color: 'rgb(230, 230, 230)',
        padding: 0,
        margin: '5px 5px 5px 0',
    },
    checked: {
        color: '#0073dd !important',
    },
    disabled: {
        color: '#ADB0B5 !important',
    }
}))(Checkbox)

export default Check
