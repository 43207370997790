export const CHANGE_ORDER = 'order/CHANGE_ORDER';
export const changeOrder = (payload) => {
    return {
        type: CHANGE_ORDER,
        payload
    }
}

export const SET_EDITED_ORDER = 'order/SET_EDITED_ORDER';
export const setEditOrder = (payload) => {
    return {
        type: SET_EDITED_ORDER,
        payload,
    }
}

export const SET_EDIT_PRESCRIPTION = 'order/SET_EDIT_PRESCRIPTION';
export const setEditPrescription = (payload) => {
    return {
        type: SET_EDIT_PRESCRIPTION,
        payload,
    }
}

export const SET_IS_EDITED = 'order/SET_IS_EDITED';
export const setIsEdited = (payload) => {
    return {
        type: SET_IS_EDITED,
        payload,
    }
}

export const SET_IS_EDIT_BOOKING = 'order/SET_IS_EDIT_BOOKING';
export const setIsEditBooking = (payload) => {
    return {
        type: SET_IS_EDIT_BOOKING,
        payload,
    }
}

export const SET_APPOINTMENT_EXPIRATION_TIME = 'order/SET_APPOINTMENT_EXPIRATION_TIME';
export const setAppointmentExpirationTime = (payload) => {
    return {
        type: SET_APPOINTMENT_EXPIRATION_TIME,
        payload,
    }
}

export const CREATE_PRESCRIPTION_REQUEST = 'order/CREATE_PRESCRIPTION_REQUEST';
export const createPrescriptionRequest = (data) => {
    return {
        method: 'POST',
        endpoint: `/patient_prescription_requests`,
        query: data,
        type: CREATE_PRESCRIPTION_REQUEST,
    }
}

export const SET_PREV_APPOINTMENT_ROUTE = 'order/SET_PREV_APPOINTMENT_ROUTE';
export const setPrevAppointmentRoute = (route) => {
    return {
        type: SET_PREV_APPOINTMENT_ROUTE,
        payload: route,
    }
}

export const SET_IS_FROM_PROVIDER = 'order/SET_IS_FROM_PROVIDER';
export const setIsFromProvider = (flag) => {
    return {
        type: SET_IS_FROM_PROVIDER,
        payload: flag,
    }
}