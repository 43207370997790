import React, {FC} from "react";

import './LoaderComponent.scss';
import AnimationComponent from "../../errorOrSuccessPage/AnimationComponent";

// type IProps = {
//     style?: CSSProperties
// }

// const LoaderComponent: FC<IProps> = ({
//     style = {},
// }: IProps) => {
//     return (
//         <div id="loader-container" style={style}>
//             <div className="loader-circle">
//                 <div className="loader-circle1 loader-child" />
//                 <div className="loader-circle2 loader-child" />
//                 <div className="loader-circle3 loader-child" />
//                 <div className="loader-circle4 loader-child" />
//                 <div className="loader-circle5 loader-child" />
//                 <div className="loader-circle6 loader-child" />
//                 <div className="loader-circle7 loader-child" />
//                 <div className="loader-circle8 loader-child" />
//                 <div className="loader-circle9 loader-child" />
//                 <div className="loader-circle10 loader-child" />
//                 <div className="loader-circle11 loader-child" />
//                 <div className="loader-circle12 loader-child" />
//             </div>
//         </div>
//     );
// };

const LoaderComponent: FC = () => {
    return <AnimationComponent
        animation={'loader'}
        onComplete={() => {}}
        title={''}
        subtitle={''}
        loop={true}
    />
};

export default React.memo(LoaderComponent);
