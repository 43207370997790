import React, {FC} from "react";
import {Link} from "react-router-dom";

import './styles.scss';

import Logo from "../../../assets/logo.svg";

type IProps = {
    handleClick: () => void;
    link: string;
}

const StyledSignInButton: FC<IProps> = ({handleClick, link}: IProps) => {
    return (
        <Link onClick={handleClick} to={link} className="signInButton--button-wrapper">
            <div className="signInButton--image-wrapper"><img className="signInButton--image" src={Logo} /></div>
            Sign in
        </Link>
    )
}

export default React.memo(StyledSignInButton)