export default Object.freeze({
    ADD_CARD: 'payments/ADD_CARD',
    ADD_CARD_SUCCESS: 'payments/ADD_CARD.SUCCESS',
    ADD_CARD_FAILURE: 'payments/ADD_CARD.FAILURE',

    ADD_CARD_ATHENA: 'payments/ADD_CARD_ATHENA',
    ADD_CARD_ATHENA_SUCCESS: 'payments/ADD_CARD_ATHENA.SUCCESS',
    ADD_CARD_ATHENA_FAILURE: 'payments/ADD_CARD_ATHENA.FAILURE',

    GET_PAYMENTS: 'payments/GET_PAYMENTS',
    GET_PAYMENTS_SUCCESS: 'payments/GET_PAYMENTS.SUCCESS',
    GET_PAYMENTS_FAILURE: 'payments/GET_PAYMENTS.FAILURE',

    SELECT_CARD: 'payments/SELECT_CARD',
    SELECT_CARD_SUCCESS: 'payments/SELECT_CARD.SUCCESS',
    SELECT_CARD_FAILURE: 'payments/SELECT_CARD.FAILURE',

    DELETE_CARD: 'payments/DElETE_CARD',
    DELETE_CARD_SUCCESS: 'payments/DElETE_CARD.SUCCESS',
    DELETE_CARD_FAILURE: 'payments/DElETE_CARD.FAILURE',

    DELETE_CARD_ATHENA: 'payments/DELETE_CARD_ATHENA',
    DELETE_CARD_ATHENA_SUCCESS: 'payments/DELETE_CARD_ATHENA.SUCCESS',
    DELETE_CARD_ATHENA_FAILURE: 'payments/DELETE_CARD_ATHENA.FAILURE',

    CREATE_PAYMENT_INTENT: 'payments/CREATE_PAYMENT_INTENT',
    CREATE_PAYMENT_INTENT_SUCCESS: 'payments/CREATE_PAYMENT_INTENT.SUCCESS',
    CREATE_PAYMENT_INTENT_FAILURE: 'payments/CREATE_PAYMENT_INTENT.FAILURE',

    GET_PAYMENT_INTENT: 'payments/GET_PAYMENT_INTENT',
    GET_PAYMENT_INTENT_SUCCESS: 'payments/GET_PAYMENT_INTENT.SUCCESS',
    GET_PAYMENT_INTENT_FAILURE: 'payments/GET_PAYMENT_INTENT.FAILURE',

    CLEAR_PAYMENT_INTENT: 'payments/CLEAR_PAYMENT_INTENT',
    CLEAR_PAYMENT_INTENT_SUCCESS: 'payments/CLEAR_PAYMENT_INTENT.SUCCESS',
    CLEAR_PAYMENT_INTENT_FAILURE: 'payments/CLEAR_PAYMENT_INTENT.FAILURE',

    RECORD_PAYMENT: 'payments/RECORD_PAYMENT',
    RECORD_PAYMENT_SUCCESS: 'payments/RECORD_PAYMENT.SUCCESS',
    RECORD_PAYMENT_FAILURE: 'payments/RECORD_PAYMENT.FAILURE',

    EDIT_CARD: 'payments/EDIT_CARD',
    EDIT_CARD_SUCCESS: 'payments/EDIT_CARD.SUCCESS',
    EDIT_CARD_FAILURE: 'payments/EDIT_CARD.FAILURE',

    SELECT_PRIMARY_CARD: 'payments/SELECT_PRIMARY_CARD',
    SELECT_PRIMARY_CARD_SUCCESS: 'payments/SELECT_PRIMARY_CARD.SUCCESS',
    SELECT_PRIMARY_CARD_FAILURE: 'payments/SELECT_PRIMARY_CARD.FAILURE',

    SET_FROM_TO_PAYMENT_FLOW: 'payments/SET_FROM_TO_PAYMENT_FLOW',
    SET_FROM_TO_PAYMENT_FLOW_SUCCESS: 'payments/SET_FROM_TO_PAYMENT_FLOW_SUCCESS',
    SET_FROM_TO_PAYMENT_FLOW_FAILURE: 'payments/SET_FROM_TO_PAYMENT_FLOW_FAILURE',
})