import React, {FC, useMemo} from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import animationN1 from '../../lotties/Flight-Health-Feedback-Animation-1-Json.json'
import animationN3 from '../../lotties/Flight-Health-Loader-1-Json.json'
import {IErrorOrSuccessScreen} from "../../Redux/types/modal";

type IProps = {
    onComplete: IErrorOrSuccessScreen["onComplete"];
    animation: IErrorOrSuccessScreen["animation"];
    title: IErrorOrSuccessScreen["title"];
    subtitle: IErrorOrSuccessScreen["subtitle"];
    loop?: boolean;
}

const AnimationComponent: FC<IProps> = ({onComplete, animation, title, subtitle, loop = false}: IProps) => {

    const animationData = useMemo(() => {
        if(animation === 'loader') return animationN3;
        return animationN1;
    }, [animation])

    return (
        <div className='animation-wrapper' >
            <Player
                autoplay={true}
                loop={loop}
                src={animationData}
                speed={1.5}
                style={{height: '100px', width: '100px'}}
                onEvent={(event) => {
                    if (event === 'complete') onComplete && onComplete();
                }}
            />
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
        </div>
    );
}

export default React.memo(AnimationComponent)