import React, {FC} from 'react';
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../../../styles";
import {StyledButton} from "../../buttons";
import typesButton from "../../../Helpers/constants/typesStyledButton.costants";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles((theme) => (
    {
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginTop: 100,
            marginRight: '15%',
            marginLeft: '45%',
            zIndex: '99 !important',

            '& > div': {
                '&:first-child': {
                    backgroundColor: 'rgba(241,245,250,0.5) !important',
                    backdropFilter: 'blur(3px)',
                }
            },

            [theme.breakpoints.down('sm')]: {
                marginRight: '5%',
                marginLeft: '5%',
            },
        },
        modalWrapper: {
            backgroundColor: '#FFFFFF',
            boxSizing: 'border-box',
            maxWidth: 493,
            maxHeight: 550,
            overflowY: 'auto',
            width: '100%',
            borderRadius: 8,
            padding: 22,
            [theme.breakpoints.down('xs')]: {
                margin: 15,
                padding: 10,
            },
        },
        modalHead: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        modalCross: {
            color: styles.textColor,
            height: 24,
            width: 24,
            cursor: 'pointer',
        },
        modalContent: {
            padding: '0 26px 26px 34px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px 10px 10px'
            }
        },
        modalName: {
            padding: '10px 0',
            fontSize: 21,
            fontWeight: 800,
            lineHeight: '29px',
            letterSpacing: '0.25px',
        },
        subtitle: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '23px',
            marginBottom: 15,
        },
        modalButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
        },
        selectorsWrapper: {
            display: "flex",
            justifyContent: "space-between"
        },
        effectiveSelect: {
            marginTop: 30,
        },
        placeholder: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 16,
        },
        selectorLabel: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '28px',
            color: '#5B616A',
        }
    }
));

type IProps = {
    isSubmit: boolean;
    handleSubmit: () => void;
    handleClose: () => void;
}

const ModalRemoveInsurance: FC<IProps> = ({
    isSubmit,
    handleSubmit,
    handleClose,
}) => {
    const classes: any = useStyles();

    return (
        <div>
            <div className={classes.modalWrapper}>
                <div className={classes.modalHead}>
                    <CloseIcon className={classes.modalCross} onClick={handleClose} />
                </div>
                <div className={classes.modalContent}>
                    <h4 className={classes.modalName}>
                        Delete insurance?
                    </h4>
                    <h6 className={classes.subtitle}>
                        You will no longer be able to apply it to cover treatment expenses
                    </h6>
                    <div className={classes.modalButtons}>
                        <StyledButton
                            disable={isSubmit}
                            title={'Keep it'}
                            style={{marginRight: '10px'}}
                            type={typesButton.Secondary}
                            handleClick={handleClose}
                        />
                        <StyledButton
                            disable={isSubmit}
                            title={'Yes, delete it'}
                            type={typesButton.Error}
                            handleClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(ModalRemoveInsurance);