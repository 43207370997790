import {useEffect, useRef} from "react";

const INACTIVITY_TIME = 30 * 60 * 1000;

function startTimer (timer, callback) {
    timer.current = setTimeout(() => {
        callback();
        clearTimeout(timer.current)
    }, INACTIVITY_TIME)
}

function refreshTime (timer, callback) {
    clearTimeout(timer.current)
    startTimer(timer, callback);
}

const useInactivityTime = (callback: () => void): void => {
    const timer = useRef(null);

    const handleRefreshTime = () => refreshTime(timer, callback);

    useEffect(() => {
        startTimer(timer, callback);
        document.addEventListener('mousemove', handleRefreshTime)
        document.addEventListener('click', handleRefreshTime)
        document.addEventListener('scroll', handleRefreshTime)

        return () => {
            document.removeEventListener('mousedown', handleRefreshTime)
            document.removeEventListener('click', handleRefreshTime)
            document.removeEventListener('scroll', handleRefreshTime)
        }
    }, [])

}

export default useInactivityTime;