import { createReducer } from '../../Helpers/redux';
import storage from 'redux-persist/lib/storage'

import {
  CHANGE_AVATAR_ACTION,
  GET_BODY_PART_CODES,
  GET_INSURANCE_PACKAGES,
  GET_PATIENT_CASE_POLICIES,
  GET_USER,
  SET_CURRENT_INSURANCE,
  SET_FROM_TO_PROFILE,
  SET_INSURANCE_FLOW, SET_INSURANCE_FLOW_IN_MY_APPOINTMENTS,
  SET_INSURANCE_FLOW_VALUES,
  SET_IS_INSURANCE_FLOW_IN_BOOKING,
  VERIFY_PROFILE,
} from '../actions/users'
import {persistReducer} from "redux-persist";
import {LOG_OUT} from "../actions/auth";

const initialState = {
  users: [],
  error: null,
  totalLength: null,
  currentUser: null,
  verify: {
    from: null,
    type: null,
    value: null,
    backTo: null,
    onSuccess: null,
    onError: null,
  },
  changingAvatar: {
    isWebCamera: false,
    avatar: null,
    isCropper: false,
  },
  fromToProfile: null,
  isInsuranceFlowInBooking: null,
  insuranceFlowInMyAppointment: {
    isActive: false,
    appointmentId: null,
  },
  insuranceFlowValues: null,
  bodyPartCodes: null,
  insuranceFlow: {
    side: 'front',
    status: 'preparing',
    frontSideOfCard: {
      photo: null,
      capturedPhoto: null,
    },
    backSideOfCard: {
      photo: null,
      capturedPhoto: null,
    },
  },
  insurancePackages: null,
  patientCasePolicies: null,
  currentInsurance: null,
}

const persistConfig = {
  key: 'users',
  storage,
  blacklist: ['users', 'error', 'totalLength', 'currentUser', 'bodyPartCodes', 'insurancePackages', 'patientCasePolicies'],
}

const handlers = {
  [GET_USER + '.SUCCESS']: (prevState, { payload }) => {
    return {
      ...prevState,
      currentUser: payload,
    }
  },
  [GET_USER + '.FAILURE']: (prevState, { payload }) => {
    return {
      ...prevState,
      error: payload,
    }
  },
  [VERIFY_PROFILE]: (prevState, { payload }) => {
    return {
      ...prevState,
      verify: {
        ...payload,
      }
    }
  },
  [CHANGE_AVATAR_ACTION]: (prevState, {payload}) => {
    return {
      ...prevState,
      changingAvatar: {
        ...payload,
      }
    }
  },
  [SET_FROM_TO_PROFILE]: (prevState, {payload}) => {
    return {
      ...prevState,
      fromToProfile: payload,
    }
  },
  [SET_INSURANCE_FLOW_VALUES]: (prevState, {payload}) => {
    return {
      ...prevState,
      insuranceFlowValues: payload,
    }
  },
  [GET_BODY_PART_CODES + '.SUCCESS']: (prevState, {payload}) => {
    return {
      ...prevState,
      bodyPartCodes: payload?.body_part_codes,
    }
  },
  [GET_PATIENT_CASE_POLICIES + '.SUCCESS']: (prevState, {payload}) => {
    return {
      ...prevState,
      patientCasePolicies: payload?.patient_case_policies,
    }
  },
  [SET_INSURANCE_FLOW]: (prevState, {payload}) => {
    return {
      ...prevState,
      insuranceFlow: {
        ...prevState?.insuranceFlow,
        ...payload,
      }
    }
  },
  [GET_INSURANCE_PACKAGES + '.SUCCESS']: (prevState, {payload}) => {
    return {
      ...prevState,
      insurancePackages: payload?.insurance_packages,
    }
  },
  [SET_CURRENT_INSURANCE]: (prevState, {payload}) => {
    return {
      ...prevState,
      currentInsurance: payload,
    }
  },
  [SET_IS_INSURANCE_FLOW_IN_BOOKING]: (prevState, {payload}) => {
    return {
      ...prevState,
      isInsuranceFlowInBooking: payload,
    }
  },
  [SET_INSURANCE_FLOW_IN_MY_APPOINTMENTS]: (prevState, {payload}) => {
    return {
      ...prevState,
      insuranceFlowInMyAppointment: payload,
    }
  },
  [LOG_OUT]: () => {
    return initialState;
  }
}

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
)
