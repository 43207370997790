import React from 'react';
import {useFormik} from "formik";

import flightLogo from '../../assets/preview.svg';

import './style.scss'
import {StyledInput} from "../../components";
import {StyledButton} from "../../components/buttons";
import typesButton from "../../Helpers/constants/typesStyledButton.costants";
import {NotificationService} from "../../services";
import {useDispatch} from "react-redux";
import {setIsAuthInStaging} from "../../Redux/actions/auth";

const AuthStagingPage = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: (values) => {
            if(values.password === process.env.REACT_APP_PASSWORD_TO_WIDGET && values.username === process.env.REACT_APP_USERNAME_TO_WIDGET) {
                dispatch(setIsAuthInStaging(true))
            } else {
                NotificationService.error(undefined, 'Wrong credentials!!!');
            }
        }
    })

    const { touched, errors, values, setFieldTouched, handleChange, handleSubmit } = formik;

    return (
        <div className={'auth-staging-page'}>
            <div className={'auth-staging-wrapper'}>
                <img className={'auth-staging-wrapper--logo'} src={flightLogo} alt={'Flight Health logo'} />
                <h1 className={'auth-staging-wrapper--title'}>Login</h1>
                <StyledInput
                    error={(touched['username'] && errors['username']) as boolean}
                    variant="filled"
                    fullWidth
                    onBlur={() => setFieldTouched('username')}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    label={'Username'}
                    type={'text'}
                    id="username"
                    autoComplete="username"
                    helperText={touched['username'] && errors['username']}
                    className={'auth-staging-wrapper--input'}
                />
                <StyledInput
                    error={(touched['password'] && errors['password']) as boolean}
                    variant="filled"
                    fullWidth
                    onBlur={() => setFieldTouched('password')}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    label={'Password'}
                    type={'password'}
                    id="password"
                    autoComplete="password"
                    helperText={touched['password'] && errors['password']}
                    className={'auth-staging-wrapper--input'}
                />
                <StyledButton title={'Login'} type={typesButton.Secondary} handleClick={handleSubmit}/>
            </div>
        </div>
    )
}

export default React.memo(AuthStagingPage);