import React, {FC} from 'react'
import './styles.scss'

type IProps = {
    title: string;
    position: string;
    handleClick: () => void;
}

const StyledUpperButton: FC<IProps> = ({title, position, handleClick}: IProps) => {
    return (
        <button className={`SignInButton ${position ? position : 'right'}`} onClick={handleClick}>
            {title}
        </button>
    )
}

export default React.memo(StyledUpperButton);