import React, {FC, MouseEventHandler, useEffect} from 'react'

import successIcon from '../../assets/successIcon.svg'
import errorIcon from '../../assets/errorIcon.svg'
import {StyledButton} from "../../components/buttons";
import {useSelector} from "react-redux";
import {getCurrentTenant} from "../../Redux/selectors/tenant";
import typesButton from "../../Helpers/constants/typesStyledButton.costants";
import {IErrorOrSuccessScreen} from "../../Redux/types/modal";

const contacts = ['Office Phone', 'After hours', 'Send a text']

type IProps = {
    type: IErrorOrSuccessScreen["typeScreen"];
    title: IErrorOrSuccessScreen["title"];
    subtitle: IErrorOrSuccessScreen["subtitle"];
    description: IErrorOrSuccessScreen["description"];
    isChat: IErrorOrSuccessScreen["isChat"];
    isMyAppointments: IErrorOrSuccessScreen["isMyAppointments"];
    onBookNewAppointment: () => void;
    onViewMyAppointment: () => void;
    isExpirationTime: IErrorOrSuccessScreen["isExpirationTime"];
    onExpiredCancel: () => void;
    onExpiredTryAgain: () => void;
    onComplete: IErrorOrSuccessScreen["onComplete"];
    link: IErrorOrSuccessScreen["link"];
    onLink: () => void;
    isResetPasswordWithEmail: IErrorOrSuccessScreen["isResetPasswordWithEmail"];
    onResendLink: IErrorOrSuccessScreen["onResendLink"];
    onSendReport: () => void;
}

const SuccessOrErrorComponent: FC<IProps> = ({
         type,
         title,
         subtitle,
         description,
         isChat,
         isMyAppointments,
         onBookNewAppointment,
         onViewMyAppointment,
         isExpirationTime,
         onExpiredCancel,
         onExpiredTryAgain,
         onComplete,
         link,
         onLink,
         isResetPasswordWithEmail,
         onResendLink = () => {},
         onSendReport = () => {}
}: IProps) => {
    const tenant = useSelector(getCurrentTenant)

    useEffect(() => {
        setTimeout(() => {
            onComplete && onComplete()
        }, 2500)
    }, [])

    return (
        <div className='error-or-success-wrapper'>
            {type === 'success' ?
                <div className='error-or-success-wrapper--success-img-wrapper'><img src={successIcon} /></div> :
                <div className='error-or-success-wrapper--success-img-wrapper'><img src={errorIcon} /></div>
            }
            {title && <h1 className='error-or-success-wrapper--title'>{title}</h1>}
            {subtitle && <p className='error-or-success-wrapper--subtitle'>{subtitle}</p>}
            {isResetPasswordWithEmail && (
                <div className='error-or-success-wrapper--description-wrapper'>
                    <p>Didn’t receive the link?</p>
                    <p>
                        <a onClick={onResendLink as MouseEventHandler<HTMLAnchorElement>}>Resend the link&nbsp;</a>
                        or
                        <a href={`tel:${tenant?.phone || ''}`}>&nbsp;contact support</a>
                    </p>
                </div>
            )}
            {isChat &&  (<div className='error-or-success-wrapper--chat-wrapper'>
                <div className='error-or-success-wrapper--contacts-wrapper'>
                    <div className='contacts-wrapper--phones'>
                        {contacts.map(contact => <div>
                            <p className='contacts-wrapper--phones--title'>{contact}</p>
                            <p className='contacts-wrapper--phones--phone'>{`+1 ${tenant?.phone}`}</p>
                        </div>)}
                    </div>
                    { tenant?.practice_schedule?.length ? (<div className='contacts-wrapper--schedule'>
                        {tenant?.practice_schedule?.map(schedule => <p>{schedule}</p>)}
                    </div>) : null}
                </div>
            </div>)}
            {isExpirationTime && (<div className='error-or-success-wrapper--expiration-wrapper'>
                <p className='error-or-success-wrapper--expiration-title'>Booking time has expired.</p>
                <p className='error-or-success-wrapper--expiration-subtitle'>Please, choose the appointment details again</p>
                <div className='error-or-success-wrapper--expiration-button-wrapper'>
                    <StyledButton type={typesButton.Secondary} title={'Cancel'} fullWidth handleClick={onExpiredCancel} />
                    <StyledButton type={typesButton.Primary} title={'Try again'} fullWidth handleClick={onExpiredTryAgain} />
                </div>
            </div>)}
            {isMyAppointments && (
                <div className='error-or-success-wrapper--myAppointment-button-wrapper'>
                    <StyledButton
                        type={typesButton.Secondary}
                        title={'Book a new appointment'}
                        fullWidth
                        handleClick={onBookNewAppointment}
                    />
                    <StyledButton
                        type={typesButton.Primary}
                        title={'View my appointments'}
                        fullWidth
                        handleClick={onViewMyAppointment}
                    />
                </div>
            )}
            {description?.length && <div className='error-or-success-wrapper--description-wrapper'>
                {description.map(descriptionItem => <p>{descriptionItem}</p>)}
            </div>}
            {link && <p className='error-or-success-wrapper--link' onClick={onLink}>{link}</p>}
            {isChat ? (
                <React.Fragment>
                    <div className='error-or-success-wrapper--hr-wrapper'><hr/> or <hr/></div>
                    <StyledButton
                        type={typesButton.Secondary}
                        title={'Send us a support message'}
                        fullWidth
                        style={{marginTop: '15px', marginBottom: '40px', maxWidth: '550px'}}
                        handleClick={onSendReport}
                    />
                </React.Fragment>
            ) : null}
        </div>
    )
}

export default React.memo(SuccessOrErrorComponent)