import React, {FC, useCallback, useMemo, useState} from 'react'
import {Modal} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch, useSelector} from "react-redux";
import {getCancelAppointmentModal} from "../../../Redux/selectors/modal";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../../../styles";
import {StyledButton} from "../../buttons";
import typesButton from "../../../Helpers/constants/typesStyledButton.costants";
import {closeCancelAppointmentModal} from "../../../Redux/actions/modal";
import LoaderComponent from "../../../containers/auth/appointment/LoaderComponent";
import {IModalTypes} from "../../../Redux/types/modal";
import ModalRemoveInsurance from "../modalRemoveInsurance";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles((theme) => (
    {
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginTop: 100,
            marginRight: '15%',
            marginLeft: '45%',
            zIndex: '99 !important',

            '& > div': {
                '&:first-child': {
                    backgroundColor: 'rgba(241,245,250,0.5) !important',
                    backdropFilter: 'blur(3px)',
                }
            },

            [theme.breakpoints.down('sm')]: {
                marginRight: '5%',
                marginLeft: '5%',
            },
        },
        modalWrapper: {
            backgroundColor: '#FFFFFF',
            boxSizing: 'border-box',
            maxWidth: 493,
            maxHeight: 550,
            overflowY: 'auto',
            width: '100%',
            borderRadius: 8,
            padding: 22,
            [theme.breakpoints.down('xs')]: {
                margin: 15,
                padding: 10,
            },
        },
        modalHead: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        modalCross: {
            color: styles.textColor,
            height: 24,
            width: 24,
            cursor: 'pointer',
        },
        modalContent: {
            padding: '0 26px 26px 34px',
            [theme.breakpoints.down('sm')]: {
                padding: '0 10px 10px 10px'
            }
        },
        modalName: {
            padding: '10px 0',
            fontSize: 21,
            fontWeight: 800,
            lineHeight: '29px',
            letterSpacing: '0.25px',
        },
        subtitle: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '23px',
            marginBottom: 15,
        },
        modalButtons: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
        },
        selectorsWrapper: {
            display: "flex",
            justifyContent: "space-between"
        },
        effectiveSelect: {
            marginTop: 30,
        },
        placeholder: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 16,
        },
        selectorLabel: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '28px',
            color: '#5B616A',
        }
    }
));

const ModalCancelAppointment: FC = () => {
    const classes: any = useStyles();
    const dispatch = useDispatch();
    const cancelAppointmentModal = useSelector(getCancelAppointmentModal)
    const [isSubmit, setIsSubmit] = useState(false);

    const closeModal = useCallback(() => {
        dispatch(closeCancelAppointmentModal())
        setIsSubmit(false)
    }, [])

    const onSubmit = useCallback(() => {
        if(!isSubmit) {
            setIsSubmit(true);
            cancelAppointmentModal.handleSubmit && cancelAppointmentModal.handleSubmit(setIsSubmit)
        }
    }, [cancelAppointmentModal, isSubmit])

    const content = useMemo(() => {
        if(cancelAppointmentModal.typeModal === IModalTypes.REMOVE_INSURANCE) {
            return <ModalRemoveInsurance
                isSubmit={isSubmit}
                handleSubmit={onSubmit}
                handleClose={closeModal}
            />
        } else {
            return (
                <div className={classes.modalWrapper}>
                    <div className={classes.modalHead}>
                        <CloseIcon className={classes.modalCross} onClick={closeModal} />
                    </div>
                    <div className={classes.modalContent}>
                        <h4 className={classes.modalName}>{cancelAppointmentModal.title}</h4>
                        <h6 className={classes.subtitle}>{cancelAppointmentModal?.subtitle}</h6>
                        {!cancelAppointmentModal?.isDeleteCard && isSubmit ? <LoaderComponent/> : null}
                        {cancelAppointmentModal?.isDeleteCard ? (<div className={classes.modalButtons}>
                            <StyledButton
                                disable={isSubmit}
                                title={'Cancel'}
                                style={{marginRight: '10px'}}
                                type={typesButton.Secondary}
                                handleClick={closeModal}
                            />
                            <StyledButton
                                disable={isSubmit}
                                title={'Delete'}
                                type={typesButton.Error}
                                handleClick={onSubmit}
                            />
                        </div>) : (<div className={classes.modalButtons}>
                            <StyledButton
                                disable={isSubmit}
                                title={'Keep appointment'}
                                style={{marginRight: '10px'}}
                                type={typesButton.Secondary}
                                handleClick={closeModal}
                            />
                            <StyledButton
                                disable={isSubmit}
                                title={'Cancel appointment'}
                                type={typesButton.Primary}
                                handleClick={onSubmit}
                            />
                        </div>)}
                    </div>
                </div>
            )
        }
    }, [cancelAppointmentModal, isSubmit])

    return (
        <div>
            {cancelAppointmentModal.isOpen && <Modal
                open={cancelAppointmentModal.isOpen}
                onClose={() => {}}
                className={classes.modal}
            >
                {content}
            </Modal>}
        </div>
    );
}

export default React.memo(ModalCancelAppointment);