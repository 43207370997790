import React, {FC} from 'react'

type IProps = {
    title: string;
    img: string;
    handleClick: () => void;
}

const VariationLoginButton: FC<IProps> = ({title, img, handleClick}: IProps) => {
    return (
        <button type="button"
                onClick={handleClick}
                className="social-media-button">
            <img src={img} alt={title.toLowerCase()}/>
            Continue with {title}
        </button>
    )
}

export default React.memo(VariationLoginButton)