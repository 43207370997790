import persistReducer from "redux-persist/es/persistReducer";
import {createReducer} from "../../Helpers/redux";
import storage from "redux-persist/lib/storage";

import paymentsConstants from '../constants/payments'

const initialState = {
    card: {},
    selectedCard: null,
    payments: [],
    paymentError: null,
    clientSecret: null,
    isEditCard: false,
    editedCard: null,
    fromToPaymentFlow: null,
    fromToAddCreditCard: null,
};

const persistConfig = {
    key: "payments",
    storage,
    whiteList: ["type", "doctor", "appointment"],
};
const handlers = {
    [paymentsConstants.ADD_CARD_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            ...payload,
            error: null,
        };
    },
    [paymentsConstants.ADD_CARD_FAILURE]: (prevState, {payload}) => {
        return {
            ...prevState,
            error: payload,
        };
    },
    [paymentsConstants.GET_PAYMENTS_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            payments: payload.tenant_payment_methods
        };
    },
    [paymentsConstants.GET_PAYMENTS_FAILURE]: (prevState) => {
        return {
            ...prevState,
            paymentError: 'Something went wrong'
        };
    },
    [paymentsConstants.SELECT_CARD]: (prevState, {payload}) => {
        return {
            ...prevState,
            selectedCard: payload,
        };
    },
    [paymentsConstants.GET_PAYMENT_INTENT_SUCCESS]: (prevState, {payload}) => {
        return {
            ...prevState,
            clientSecret: payload.client_secret,
        }
    },
    [paymentsConstants.CLEAR_PAYMENT_INTENT]: (prevState) => {
        return {
            ...prevState,
            clientSecret: null,
        }
    },
    [paymentsConstants.EDIT_CARD]: (prevState, {payload}) => {
        return {
            ...prevState,
            isEditCard: payload?.isEditCard,
            editedCard: payload?.selectedCard,
        }
    },
    [paymentsConstants.SET_FROM_TO_PAYMENT_FLOW]: (prevState, {payload}) => {
        return {
            ...prevState,
            fromToPaymentFlow: payload,
        }
    }
};

export default persistReducer(
    persistConfig,
    createReducer(initialState, handlers)
);
