export const routes = {
    tenants: "/",
    recoveryPassword: '/:slug/recovery-password',
    verifyEmail: '/:slug/verify-email',
    userAppointments: "/:slug/user-appointments",
    userAppointment: "/:slug/user-appointments/edit",
    userPrescription: "/:slug/user-appointments/edit-prescription",
    home: "/:slug",
    login: "/:slug/login",
    signup: "/:slug/signup",
    signin: "/:slug/signin",
    insuranceProvider: "/:slug/insurance-provider",
    doctorProvider: "/:slug/doctor-provider",
    profile: {
        home: "/:slug/profile",
        profileBasicInformation: "/:slug/profile/basic",
        profileEmailInformation: "/:slug/profile/email",
        profilePhoneInformation: "/:slug/profile/phone",
        profilePasswordInformation: "/:slug/profile/password",
        profileVerify: "/:slug/profile/verify",
    },
    chooseAppointment: "/:slug/choose-appointment-type",
    chooseSpecificType: "/:slug/choose-specific-type",
    additionalSpecificType: "/:slug/additional-specific-type",
    appointment: '/:slug/appointment/',
    verify: '/:slug/verify',
    confirmation: '/:slug/confirmation',
    summary: '/:slug/summary',
    paymentPage: '/:slug/payment-page',
    payAddCard: '/:slug/pay-add-card',
    paymentCards: '/:slug/payment-cards',
    coincidencePage: '/:slug/coincidence-page',
    insuranceFlow: {
        home: '/:slug/insurance',
        coverages: '/:slug/insurance/coverages',
        workplaceAccident: '/:slug/insurance/workplace-accident',
        automotiveAccident: '/:slug/insurance/automotive-accident',
        otherBenefits: '/:slug/insurance/other-benefits',
        courtMandated: '/:slug/insurance/court-mandated',
        homeInsurance: '/:slug/insurance/home',
        photos: '/:slug/insurance/photos',
        takingPhoto: {
            home: '/:slug/insurance/taking-photo',
            front: '/:slug/insurance/taking-photo/front',
            back: '/:slug/insurance/taking-photo/back',
        },
        describing: '/:slug/insurance/insurance-info',
        reviewInsurance: '/:slug/insurance/review-insurance',
        reviewCoverage: '/:slug/insurance/review-coverage',
    },
    support: {
        home: '/:slug/support',
        errorReport: '/:slug/support/error-report'
    }
}
