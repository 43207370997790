import React, {useCallback} from 'react';
import {useHistory} from "react-router-dom";
import {GoogleLogin} from 'react-google-login';
import {useSelector, useDispatch} from 'react-redux';

import {verifyGoogleAndApple} from "../../../Redux/actions/verification";
import {logInByGoogle, SetPrefillData, setTypeAuthorization} from '../../../Redux/actions/auth';

import {getOrder} from "../../../Redux/selectors/order";
import {getIsNewPatientFlow} from "../../../Redux/selectors/auth";
import {getActiveInsuranceId, getSlug} from "../../../Redux/selectors/tenant";

import googleIcon from '../../../assets/google.svg';

import {CLIENT_ID} from "../../../Helpers/constants/googleLoginButton.constants";

const GoogleLoginButton = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const slug = useSelector(getSlug);
    const order = useSelector(getOrder);
    const insuranceId = useSelector(getActiveInsuranceId);
    const isNewPatientFlow = useSelector(getIsNewPatientFlow);

    const loginToSystem = useCallback((idToken, profile) => {
        dispatch(verifyGoogleAndApple({ email: profile.email })).then((response) => {
            if(response.statusCode === 200) {
                dispatch(SetPrefillData(profile))
                dispatch(setTypeAuthorization('google'));
                history.push(`/${slug}/signup`)
            } else {
                dispatch(logInByGoogle(idToken, order, insuranceId, history, slug, isNewPatientFlow))
            }
        })
    }, [order, insuranceId, slug, isNewPatientFlow])

    const isGoogleLoginResponse = (response) => {
        return !!response && typeof response === 'object' && !!(response).tokenObj;
    };

    const responseGoogle = (response) => {
        if (!isGoogleLoginResponse(response)) {
            return;
        }
        const {id_token} = response.tokenObj;
        const {profileObj} = response;
        loginToSystem(id_token, {...profileObj, firstName: profileObj?.givenName, lastName: profileObj?.familyName,})
    }

    return (
        <GoogleLogin
            clientId={CLIENT_ID}
            render={renderProps =>
                <button type="button"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="social-media-button">
                    <img src={googleIcon} alt='google'/>
                    Continue with Google
                </button>
            }
            fetchBasicProfile={true}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            // scope={'profile email https://www.googleapis.com/auth/calendar'}
            scope={'profile email'}
        />
    )
}

export default React.memo(GoogleLoginButton)