export const CHANGE_USER_PARAMS = 'users/CHANGE_USER_PARAMS';
export const changeUserParams = (token, data, id) => {
  return {
    method: 'PUT',
    endpoint: `users/${id}`,
    query: {
      ...data
    },
    type: CHANGE_USER_PARAMS,
  }
}

export const GET_USER = 'users/GET_USER'
export const getUser = (token, id) => {
  return {
    method: 'GET',
    endpoint: `users/${id}`,
    type: GET_USER,
  }
}

export const VERIFY_PROFILE = 'users/VERIFY_PROFILE';
export const verifyProfile = (payload) => {
  return {
    type: VERIFY_PROFILE,
    payload: payload,
  }
}

export const UPDATE_PASSWORD = 'users/UPDATE_PASSWORD';
export const updatePassword = (payload) => {
  return {
    method: 'POST',
    endpoint: `authorized_password_update`,
    type: UPDATE_PASSWORD,
    query: {
      ...payload
    }
  }
}

export const CHANGE_AVATAR_ACTION = 'users/CHANGE_AVATAR_ACTION'
export const changeAvatarAction = (payload) => {
  return {
    type: CHANGE_AVATAR_ACTION,
    payload: payload,
  }
}

export const SET_FROM_TO_PROFILE = 'users/SET_FROM_TO_PROFILE';
export const setFromToProfile = (from) => {
  return {
    type: SET_FROM_TO_PROFILE,
    payload: from,
  }
}

export const SET_INSURANCE_FLOW_VALUES = 'users/SET_INSURANCE_FLOW_VALUES';
export const setInsuranceFlowValues = (values) => {
  return {
    type: SET_INSURANCE_FLOW_VALUES,
    payload: values,
  }
}

export const GET_BODY_PART_CODES = 'users/GET_BODY_PART_CODES';
export const getBodyPartCodes = () => {
  return {
    method: 'GET',
    endpoint: `bode_part_codes`,
    type: GET_BODY_PART_CODES,
  }
}

export const CREATE_PATIENT_CASE_POLICY = 'users/CREATE_PATIENT_CASE_POLICY';
export const createPatientCasePolicy = (data) => {
  return {
    method: 'POST',
    endpoint: `patient_case_policies`,
    query: data,
    type: CREATE_PATIENT_CASE_POLICY,
  }
}

export const GET_PATIENT_CASE_POLICIES = 'users/GET_PATIENT_CASE_POLICIES';
export const getPatientCasePolicies = () => {
  return {
    method: 'GET',
    endpoint: `patient_case_policies`,
    type: GET_PATIENT_CASE_POLICIES,
  }
}

export const SET_INSURANCE_FLOW = 'users/SET_INSURANCE_FLOW';
export const setInsuranceFlow = (values) => {
  return {
    type: SET_INSURANCE_FLOW,
    payload: values,
  }
}

export const CREATE_PATIENT_INSURANCE = 'users/CREATE_PATIENT_INSURANCE';
export const createPatientInsurance = (data) => {
  return {
    method: 'POST',
    endpoint: `patient_insurances`,
    query: data,
    type: CREATE_PATIENT_INSURANCE,
  }
}

export const GET_PATIENT_INSURANCE = 'users/GET_PATIENT_INSURANCE';
export const getPatientInsurance = () => {
  return {
    method: 'GET',
    endpoint: `patient_insurances`,
    type: GET_PATIENT_INSURANCE,
  }
}

export const GET_INSURANCE_PACKAGES = 'users/GET_INSURANCE_PACKAGES';
export const getInsurancePackages = () => {
  return {
    method: 'GET',
    endpoint: `insurance_packages`,
    type: GET_INSURANCE_PACKAGES,
  }
}

export const SET_CURRENT_INSURANCE = 'users/SET_CURRENT_INSURANCE';
export const setCurrentInsurance = (insurance) => {
  return {
    type: SET_CURRENT_INSURANCE,
    payload: insurance,
  }
}

export const DELETE_PATIENT_CASE_POLICY = 'users/DELETE_PATIENT_CASE_POLICY';
export const deletePatientCasePolicy = (id) => {
  return {
    method: 'DELETE',
    endpoint: `patient_case_policies/${id}`,
    type: DELETE_PATIENT_CASE_POLICY,
  }
}

export const DELETE_PATIENT_INSURANCE = 'users/DELETE_PATIENT_INSURANCE';
export const deletePatientInsurance = (id) => {
  return {
    method: 'DELETE',
    endpoint: `patient_insurances/${id}`,
    type: DELETE_PATIENT_INSURANCE,
  }
}

export const CHANGE_PRIMARY_PATIENT_INSURANCE = 'users/CHANGE_PRIMARY_PATIENT_INSURANCE';
export const changePrimaryPatientInsurance = (id) => {
  return {
    method: 'PUT',
    endpoint: `patient_insurances/${id}/set_primary`,
    type: CHANGE_PRIMARY_PATIENT_INSURANCE,
  }
}

export const UPLOAD_INSURANCE_PHOTO = 'users/CHANGE_PRIMARY_PATIENT_INSURANCE';
export const uploadInsurancePhoto = (data) => {
  return {
    method: 'PUT',
    endpoint: `patient_insurances/upload_photo`,
    query: data,
    type: UPLOAD_INSURANCE_PHOTO,
  }
}

export const SET_IS_INSURANCE_FLOW_IN_BOOKING = 'users/SET_IS_INSURANCE_FLOW_IN_BOOKING';
export const setIsInsuranceFlowInBooking = (flag) => {
  return {
    type: SET_IS_INSURANCE_FLOW_IN_BOOKING,
    payload: flag,
  }
}

export const SET_INSURANCE_FLOW_IN_MY_APPOINTMENTS = 'users/SET_INSURANCE_FLOW_IN_MY_APPOINTMENTS';
export const setInsuranceFlowInMyAppointments = (data) => {
  return {
    type: SET_INSURANCE_FLOW_IN_MY_APPOINTMENTS,
    payload: data,
  }
}
