import {createSelector} from 'reselect'

import {IState} from "../types";
import {ITenantState} from "../types/tenant";

const getState = (state: IState) => state.tenant

export const getCurrentTenant = createSelector(getState, (tenant) => tenant.tenant);
export const getTenants = createSelector(getState, (tenant) => tenant.tenants);
export const getActiveInsuranceId = createSelector(getState, (tenant) => tenant.insurance_id);
export const getSlug = createSelector(getState, (tenant: ITenantState): string => tenant.slug);
export const getTenantQuestionnaires = createSelector(getState, (tenant: ITenantState): any => tenant.tenantQuestionnaires);