import React, {FC, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {useStyles} from './styles';

import {getCurrentTenant} from "../../Redux/selectors/tenant";

import logo from '../../assets/logo.svg'

type IProps = {
    isSmall?: boolean;
}

export const Index: FC<IProps> = ({isSmall = false}: IProps) => {
    const currentTenant = useSelector(getCurrentTenant);

    const currentLogo = useMemo(() => {
        const logoUrl = currentTenant?.logo?.url
        if(logoUrl) return {logoUrl: logoUrl, isTenant: true}
        if(!logoUrl) return {logoUrl: logo, isTenant: false}
    }, [currentTenant])

    const styles = useStyles({logoUrl: currentLogo?.logoUrl ? currentLogo?.logoUrl : ''});

    return (
        <div className={styles.wrapper}>
            <div className={`${currentLogo?.isTenant ? styles.avatarImage : styles.flightLogo} ${ isSmall && styles.small}`}/>
        </div>
    )
}

export default React.memo(Index);