import {apiConfigs} from "../configs/api";

import axios from "axios";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";

import {ICustomerEvent, IErrorReport, ITenantState} from "../types/tenant";
import {IMethodRequest, IResponseCustomAction} from "./index";
import {IState} from "../types";
import {ITenantTypeAction} from "../constants/tenant";

export const getTenants = (tenants: ITenantState['tenants']): IResponseCustomAction<string, ITenantState['tenants']> => {
  return {
    type: ITenantTypeAction.GET_TENANTS,
    payload: tenants,
  }
}

export const getTenantsFromServer = (): ThunkAction<void, IState, unknown, AnyAction> => async (dispatch: ThunkDispatch<IState, unknown, AnyAction>) => {
  try {
    const res = await axios.get(`https://api.${apiConfigs.hostUrl}/tenants`)
    dispatch(getTenants(res.data.tenants))
  } catch (error) {
    console.log(error)
  }
}

export const getTenant = (slug: string): IResponseCustomAction<string> => {
  return {
    method: IMethodRequest.GET,
    endpoint: `tenants/${slug}`,
    type: ITenantTypeAction.GET_TENANT,
  }
}

export const clearTenant = (): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.CLEAR_TENANT
  }
}

export const setSlug = (payload: ITenantState['slug']): IResponseCustomAction<string, ITenantState['slug']> => {
  return {
    type: ITenantTypeAction.SET_SLUG,
    payload
  }
}

export const setInsuranceId = (payload: ITenantState['insurance_id']): IResponseCustomAction<string, ITenantState['insurance_id']> => {
  return {
    type: ITenantTypeAction.SET_INSURANCE_ID,
    payload
  }
}

export const createInsurance = (id: ITenantState['insurance_id'], token: string): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.CREATE_INSURANCE,
    method: IMethodRequest.PUT,
    query: {insurance_id: id},
    endpoint: `users/add_insurance`,
    headers: { Authorization: `Bearer ${token}` }
  }
}

export const chooseNewInsurance = (id: ITenantState['insurance_id'], effectiveDate: string): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.CHOOSE_NEW_INSURANCE,
    method: IMethodRequest.PUT,
    query: {insurance_id: id, effectivedate: effectiveDate },
    endpoint: `users/add_insurance`,
  }
}

export const customerEvent = (data: Partial<ICustomerEvent>): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.CUSTOMER_EVENT,
    method: IMethodRequest.POST,
    query: data,
    endpoint: `customer_events`,
  }
}

export const ctaClickEvent = (data: Partial<ICustomerEvent>): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.CTA_CLICK_EVENT,
    method: IMethodRequest.POST,
    query: data,
    endpoint: `customer_events/cta_click`,
  }
}

export const sendErrorReport = (data: Partial<IErrorReport>): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.SEND_ERROR_REPORT,
    method: IMethodRequest.POST,
    query: data,
    endpoint: `complaints`,
  }
}

export const getTenantQuestionnairesFromServer = (token?: string): IResponseCustomAction<string> => {
  return {
    type: ITenantTypeAction.GET_TENANT_QUESTIONNAIRES,
    method: IMethodRequest.GET,
    endpoint: `tenant_questionnaires`,
    headers: token ? { Authorization: `Bearer ${token}` } : {}
  }
}