import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor, history } from './Redux'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',
    dsn: "https://3e28099773c24d119316c4521641c188@o4505319964803072.ingest.us.sentry.io/4505319990034432",
    integrations: [new Integrations.BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App  history={history} />
      </PersistGate>
    </Provider>
  </React.StrictMode >,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
