import {KeyboardDatePicker} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../styles";

const Picker = withStyles(() => ({
    root: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '16px',
        '& .MuiInputBase-root': {
            fontWeight: 500,
            border: '1px solid #E1E6EC',
            background: '#FFFFFF',
            boxSizing: 'border-box',
            boxShadow: '0px 6px 16px rgba(0, 115, 221, 0.1)',
            borderRadius: 8,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            borderRadius: '6px',
            backgroundColor: 'white',
            '&::placeholder': {
                color: '#5B616A'
            }
        },
        '& fieldset': {
            boxShadow: '0 6px 13px 0 rgba(0,128,246,0.1)',
            border: 'none',
        },
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            borderRadius: '6px',
            backgroundColor: 'white',
            border: 'none',
            paddingLeft: 40,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                border: `1px solid ${styles.mainColor}`
            },
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
            padding: '15px 14px !important',
        },
        '& #date-picker-inline': {
            padding: '15.5px 14px',
            '& .MuiOutlinedInput-inputAdornedEnd': {
                padding: '15px 14px',
            }
        }
    },
}))(KeyboardDatePicker)

export default Picker
