import React from 'react';
import classNames from 'classnames';

import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';

import { useNotificationStyles } from './styles';

interface IProps {
  type: 'success' | 'error' | 'info';
  title?: string;
  message: string;
  button?: React.ReactNode;
}

export const Notification: React.FC<IProps> = ({
  type = 'info',
  title,
  message = '',
  button,
}) => {
  const styles = useNotificationStyles();
  return (
    <div className={styles.container}>
      {title && <span className={styles.title}>{title}</span>}
      <div className={styles.content}>
        {type === 'success' && (
          <SuccessIcon
            className={classNames(styles.icon, styles.strokeBlack)}
          />
        )}
        {type === 'info' && <InfoIcon className={styles.icon} />}
        {type === 'error' && <ErrorIcon className={styles.icon} />}
        <p className={styles.message}>{message}</p>
      </div>
      {button}
    </div>
  );
};
