import React, {useCallback, useMemo, useState} from 'react'
import {MenuItem, Modal} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {useDispatch, useSelector} from "react-redux";
import {getChangingInsuranceModal} from "../../../Redux/selectors/modal";
import {makeStyles} from "@material-ui/core/styles";
import {styles} from "../../../styles";
import {StyledButton} from "../../buttons";
import typesButton from "../../../Helpers/constants/typesStyledButton.costants";
import {closeChangingInsuranceModal} from "../../../Redux/actions/modal";
import StyledSelect from "../../StyledSelect";
import {ReactComponent as ChevronDown} from '../../../assets/chevronDown.svg'
import {getCurrentTenant} from "../../../Redux/selectors/tenant";
import moment from "moment";
import {chooseNewInsurance} from "../../../Redux/actions/tenant";
import successIcon from '../../../assets/successIcon.svg'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalWrapper: {
        backgroundColor: styles.backgroundColor,
        boxSizing: 'border-box',
        maxWidth: 493,
        maxHeight: 550,
        overflowY: 'auto',
        width: '100%',
        borderRadius: 8,
        padding: 22,
        [theme.breakpoints.down('xs')]: {
            margin: 15,
            padding: 10,
        },
    },
    modalHead: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    modalCross: {
        color: styles.textColor,
        height: 24,
        width: 24,
        cursor: 'pointer',
    },
    modalContent: {
        padding: '0 26px 26px 34px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px 10px 10px'
        }
    },
    submittedModalIcon: {
        display: "flex",
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    modalName: {
        padding: '10px 0',
        fontSize: 21,
        fontWeight: 800,
        lineHeight: '29px',
        letterSpacing: '0.25px',
        marginBottom: 25,
    },
    submittedModalName: {
        textAlign: 'center',
        padding: '10px 0',
        fontSize: 21,
        fontWeight: 800,
        lineHeight: '29px',
        letterSpacing: '0.25px',
        marginBottom: 25,
    },
    modalButtons: {
        marginTop: 58,
        display: 'flex',
        justifyContent: 'space-between'
    },
    submittedModalButton: {
      display: "flex",
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    selectorsWrapper: {
        display: "flex",
        justifyContent: "space-between"
    },
    effectiveSelect: {
        marginTop: 30,
    },
    placeholder: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
    },
    selectorLabel: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '28px',
        color: '#5B616A',
    }
}));

const ModalChangingInsurance = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const currentTenant = useSelector(getCurrentTenant)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState('none')
    const [selectedInsurance, setSelectedInsurance] = useState('none')
    const [selectedDay, setSelectedDay] = useState('none')
    const [isSuccess, setIsSuccess] = useState(false)
    const menuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    };

    const changingInsuranceModal = useSelector(getChangingInsuranceModal)
    const closeModal = useCallback(() => {
        setIsSuccess(false);
        setSelectedYear(new Date().getFullYear());
        setSelectedMonth('none');
        setSelectedInsurance('none');
        setSelectedDay('none');
        dispatch(closeChangingInsuranceModal());
    }, [])
    const onSubmit = useCallback(() => {
        const effectivedate = moment(`${selectedMonth}/${selectedDay}/${selectedYear}`).format('MM/DD/YYYY')
        dispatch(chooseNewInsurance(selectedInsurance, effectivedate)).then((res) => {
            if(res?.payload?.message) {

            } else {
                setIsSuccess(true);
            }
        })
    }, [selectedInsurance, selectedDay, selectedMonth, selectedYear])

    const daysOfMonth = useMemo(() => {
        return Array.from(Array(moment([selectedYear, moment().month(selectedMonth).get('month')]).daysInMonth()).keys()).map((day) => day+1)
    }, [selectedYear, selectedMonth])

    const years = useMemo(() => {
        let arrayYears = []
        let minYear = new Date([2018]).getFullYear()
        let currentYear = new Date().getFullYear()
        for (let i=minYear; i <= currentYear; i++) {
            arrayYears.push(i)
        }
        return arrayYears;
    }, [])

    return (
        <div>
            {changingInsuranceModal.isOpen &&
            <Modal
                open={changingInsuranceModal.isOpen}
                onClose={() => {}}
                className={classes.modal}
            >
                <div className={classes.modalWrapper}>
                    <div className={classes.modalHead}>
                        <CloseIcon className={classes.modalCross} onClick={closeModal} />
                    </div>
                    <div className={classes.modalContent}>
                        {isSuccess ? (
                            <>
                                <img className={classes.submittedModalIcon} src={successIcon} />
                                <h4 className={classes.submittedModalName}>Information submitted</h4>
                                <StyledButton className={classes.submittedModalButton} title={'Okay'} type={typesButton.Primary} handleClick={() => closeModal()}/>
                            </>
                            ) : (<>
                            <h4 className={classes.modalName}>Has your insurnace plan changed from Aetna PPO Silver select?</h4>
                        <div>
                            <div>
                                <p className={classes.selectorLabel}>Choose your new insurance provider</p>
                                <StyledSelect
                                    IconComponent={(props) => <ChevronDown {...props} />}
                                    fullWidth={true}
                                    MenuProps={menuProps}
                                    value={selectedInsurance}
                                    onChange={(e) => setSelectedInsurance(e.target.value) }
                                    disableUnderline={true}
                                >
                                    <MenuItem disabled value={'none'}><p className={classes.placeholder}>Select insurance</p></MenuItem>
                                    {currentTenant?.practice_insurances?.map((insurance) => {
                                        return (
                                            <MenuItem value={insurance.id}>{insurance.name}</MenuItem>
                                        )
                                    })}
                                </StyledSelect>
                            </div>
                            <div className={classes.effectiveSelect}>
                                <p className={classes.selectorLabel}>Effective date</p>
                                <div className={classes.selectorsWrapper}>
                                    <StyledSelect
                                        IconComponent={(props) => <ChevronDown {...props} />}
                                        MenuProps={menuProps}
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(e.target.value) }
                                        style={{width: 165}}
                                        disableUnderline={true}
                                    >
                                        <MenuItem disabled value={'none'}><p className={classes.placeholder}>Month</p></MenuItem>
                                        {moment.months().map((month) => <MenuItem value={month}>{month}</MenuItem>)}
                                    </StyledSelect>
                                    <StyledSelect
                                        IconComponent={(props) => <ChevronDown {...props} />}
                                        MenuProps={menuProps}
                                        value={selectedDay}
                                        onChange={(e) => setSelectedDay(e.target.value)}
                                        style={{width: 97}}
                                        disableUnderline={true}
                                    >
                                        <MenuItem disabled value={'none'}><p className={classes.placeholder}>Day</p></MenuItem>
                                        {daysOfMonth?.map((day) => <MenuItem value={day}>{day}</MenuItem>)}

                                    </StyledSelect>
                                    <StyledSelect
                                        IconComponent={(props) => <ChevronDown {...props} />}
                                        MenuProps={menuProps}
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        style={{width: 110}}
                                        disableUnderline={true}
                                    >
                                        {years.map((year) => <MenuItem value={year}>{year}</MenuItem> )}
                                    </StyledSelect>
                                </div>
                            </div>
                        </div>
                        <div className={classes.modalButtons}>
                            <StyledButton title={'Cancel'} style={{marginRight: '10px'}} type={typesButton.Secondary} handleClick={closeModal}/>
                            <StyledButton title={'Submit'} type={typesButton.Primary} handleClick={onSubmit}/>
                        </div>
                        </>)}
                    </div>
                </div>
            </Modal>
            }
        </div>
    );
}

export default ModalChangingInsurance;