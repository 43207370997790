import { makeStyles } from '@material-ui/core/styles';

type IProps = {
  logoUrl: string;
}

export const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
    flex: '0 0 auto',
    width: 112,
    height: 112,
    position: 'relative',
    filter: "drop-shadow(0px 6px 16px rgba(0, 115, 221, 0.1))",
    backgroundClip: 'content-box',
    padding: 2,
    borderRadius: '50%',
    '& img': {
        position: 'absolute',
        fontSize: 24,
        top: 6,
        right: 8,
        color: '#fff'
    },
  },
  avatarImage: {
    position: 'relative',
    backgroundImage:({ logoUrl }: IProps) =>
        `url(${logoUrl})`
    ,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    borderRadius: '100%',
  },
  small: {
    width: 80,
    height: 80
  },
  flightLogo: {
    position: 'relative',
    backgroundImage:({ logoUrl }: IProps) =>
        `url(${logoUrl})`
    ,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
  }
});
