import React, {FC, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './NavBar.scss';

import logOutBlue from '../../assets/logOutBlue.svg';

import {logOut} from '../../Redux/actions/auth';
import {closeErrorOrSuccessScreen, openModal} from '../../Redux/actions/modal';
import {getCurrentUser, getIsAuth} from '../../Redux/selectors/auth';

import {useTranslation} from 'react-i18next';

import LetterContainer from '../helpers/letterContainer';
import {getSlug} from '../../Redux/selectors/tenant';
import {changeOrder} from "../../Redux/actions/order";
import {setFromToProfile} from "../../Redux/actions/users";

const NavBar: FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(getCurrentUser);
    const isAuth = useSelector(getIsAuth);
    const slug = useSelector(getSlug);

    const onHandleLogOut = () => {
        try {
            dispatch(openModal({
                isOpen: true,
                onSuccess: () => {
                    dispatch(logOut(history));
                    dispatch(changeOrder({appointment: {}, doctor: {}, paymentStatus: { status: '' }, isEditBooking: false}));
                },
                icon: logOutBlue,
                title: t('modal:signOff'),
                description: t('modal:leave'),
                customChildren: false,
                submitText: t('global:yes'),
                cancelText: t('global:no'),
                activeLeft: true
            }))
        } catch (err) {
        }
    }

    const onOpenProfile = useCallback(() => {
        if(!location.pathname.includes('profile')) dispatch(setFromToProfile(location.pathname))
        dispatch(closeErrorOrSuccessScreen())
        history.push(`/${slug}/profile`)
    }, [slug, location])

    return isAuth && <div className='navbar-container'>
        <div className='navbar-user-container' onClick={onOpenProfile}>
            <LetterContainer
                name={user.first_name}
                img={user?.photo?.url}
                nameIconClass='name-icon'
                imgClass='user-image'
            />
        </div>
        <div className='navbar-sign-off' onClick={onHandleLogOut}>
            <p>{t('signup:signoff')}</p>
        </div>
    </div>
};

export default React.memo(NavBar);
