import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'

const BigSelect = withStyles(() => ({
  root: {
    width: '100px',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
    },
    '&:focus, &:active, &:before, &.Mui-focusVisible': {
      background: 'white',
      borderRadius: 6,
    },
    background: 'white',
    height: 0,
    padding: 15,

  },
}))(Select)

export default BigSelect
