export const styles = {
    mainColor: '#0073dd',
    semiColor: '#07C4E2',
    borderColor: 'white',
    backgroundColor: '#F4F9FF',
    textColor: '#323945',
    shadow: '0 8px 18px 0 rgba(50,57,69,0.05)',
    profileShadow: 'box-shadow: 0px 6px 16px rgba(0, 115, 221, 0.1)',
    buttonShadow: '0 8px 18px 0 rgba(7,196,226,0.25)',
    transition: '0.5s',
    activeBack: 'rgba(7, 196, 226, 0.07)',
    greyBorder: '1px solid rgba(50, 57, 69, 0.1)',
    desktopWidth: '480px',
    mobileWidth: '100%',
}
